import React from "react";
import { useNavigate } from "react-router-dom";
import { FiHome, FiUser, FiSettings } from "react-icons/fi";

function BottomNavBar() {
  const navigate = useNavigate();

  return (
    <div className="fixed border border-matahati-primary-400 w-80 left-1/2 -translate-x-1/2 bg-slate-50 flex justify-center gap-12 py-4 px-8 rounded shadow-gray-400 shadow-lg">
      <button
        className="flex flex-col items-center text-matahati-primary-400"
        onClick={() => navigate("/dashboard")}
      >
        <FiHome className="text-xl" />
        <span className="text-sm">Beranda</span>
      </button>
      <button className="flex flex-col items-center text-matahati-primary-400">
        <FiUser className="text-xl" />
        <span className="text-sm">Profil</span>
      </button>
      <button className="flex flex-col items-center text-matahati-primary-400">
        <FiSettings className="text-xl" />
        <span className="text-sm">Pengaturan</span>
      </button>
    </div>
  );
}

export default BottomNavBar;
