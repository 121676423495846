import React from "react";

const DoubleOptionButtons = ({ content, bgcolor, onClick }) => {
  return (
    <div 
      className={`
        w-1/2 h-20 rounded-2xl px-4 py-2 flex items-center justify-center 
        bg-${bgcolor} shadow-neutral-700 shadow-inner 
        cursor-pointer
      `}
      onClick={onClick}
    >
      <p className="text-center font-black text-white text-2xl capitalize ">{content}</p>
    </div>
  )
};

export default DoubleOptionButtons;