import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Merokok from '../assets/merokok.png';
import Garam from '../assets/garam.png';
import Diet from '../assets/diet.webp';
import Berat from '../assets/berat.webp';
import Stres from '../assets/stres.webp';
import Malas from '../assets/malas.png';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab2Stage2 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab2Stage2 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:2, stage:2},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[2][2] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/3/1');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Faktor Resiko Hipertensi</h2>
          <button onClick={() => navigate('/edukasi/3/1')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 pt-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Faktor yang Dapat Diubah</h3>
          
          <div className="text-gray-700 text-lg">
          <ul className='mx-8 my-4' style={{listStyleType: 'circle'}}>
                <li className='mb-4'>
                    <b>Merokok</b>
                    <br/>
                    <img
            src={Merokok}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
                    Nikotin dalam rokok sangat berbahaya, karena dapat menyebabkan rusaknya 
lapisan dinding pembuluh darah sehingga tekanan darah bisa tinggi. 
Selanjutnya dapat menimbulkan penyakit stroke dan penyakit jantung.
                </li>
                <li className='mb-4'>
                    <b>Konsumsi Garam Berlebih</b>
                    <br/>
                    <img
            src={Garam}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Garam dapat meningkatkan tekanan darah dengan cepat pada beberapa orang. 
Makan makanan asin atau yang mengandung banyak garam yang 
menyebabkan penyempitan pembuliuh darah. Garam yang juga mengandung
natrium dapat menarik cairan ke dalam pembuluh darah sehingga dapat 
menyebabkan adanya penumpukan cairan dalam tubuh yang berakibat tekanan
darah menjadi tinggi.
                </li>
                <li className='mb-4'>
                    <b>Diet Rendah Serat dan Tinggi Lemak</b>
                    <br/>
                    <img
            src={Diet}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Kandungan lemak yang berlebih dalam tubuh dapat menyebabkan timbunan 
kolesterol pada dinding pembuluh darah, sehingga mengakibatkan pembuluh 
darah menyempit dan tekanan darah menjadi tinggi.
                </li>
                <li className='mb-4'>
                    <b>Berat Badan Berlebih</b>
                    <br/>
                    <img
            src={Berat}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Orang yang gemuk atau obesitas menjadi salah satu tanda adanya peningkatan 
lemak tubuh sehingga dapat menyebabkan tekanan darah tinggi. Selain itu, 
pada orang obesitas sangat beresiko terkena penyakit jantung.
                </li>
                <li className='mb-4'>
                    <b>Stres</b>
                    <br/>
                    <img
            src={Stres}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Salah satu faktor psikologis penyebab hipertensi adalah stress. Stres
yang dapat diekspresikan dengan rasa sedih, marah, takut serta rasa 
bersalah membuat kerja jantung bekerja lebih keras serta berdetak lebih 
cepat yang dapat menaikkan tekanan darah menjadi tinggi tanpa
terkendali.
                </li>
                <li>
                    <b>Kurang Aktivitas</b>
                    <br/>
                    <img
            src={Malas}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Kurang aktivitas atau olah raga menyebabkan sirkulasi darah kurang
lancar dan pembuluh darah kurang elastis. Kurang aktivitas juga 
menyebabkan obesitas.
                </li>
            </ul>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/2/1')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab2Stage2}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab2Stage2;
