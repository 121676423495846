import React, { useEffect, useState, useRef } from "react";
import crosswordQuestions from '../data/crosswordQuestions';
import Platform from "./platform";
import { CrosswordGrid, CrosswordProvider } from "@jaredreisinger/react-crossword";
import BackButton from "../components/BackButton";
import { IoCloseCircleSharp } from "react-icons/io5";
import { PopupTutorial, PopupReaction, ChooseLevel } from "../components/Popup";
import fetchPackage from "../controllers/getLevelToPlay";
import { api, getAuthHeader } from "../controllers/getUserInfo";
import { useNavigate } from "react-router-dom";
import ResultComponent from "../components/Result";

const CrosswordGame = () => {
  const crosswordRef = useRef(null);
  const [level, setLevel] = useState(0);
  const [questions, setQuestions] = useState(null);
  const [selectedClue, setSelectedClue] = useState(null);
  const [score, setScore] = useState(0);
  const [popupTutorial, setPopupTutorial] = useState(false)
  const [reactPopup, setReactPopup] = useState(null)
  const [userInfo, setUserInfo] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [isDone, setIsDone] = useState(false);
  const [popupLevel, setPopupLevel] = useState(false);

  const navigate = useNavigate();

  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login', {replace: true});
        }
    }
  };

  useEffect(() => {
    localStorage.removeItem('guesses');
    const fetchUserInfo = async () => {
      const userData = await getUserInfo();
      setUserInfo(userData);
      const set = await fetchPackage('tts', '/game/crossword');
      if(set) {
        setLevel(set);
        setQuestions(dataQuestion.set[parseInt(set)-1].questions);
      } else {
        setPopupLevel(true);
        setPopupTutorial(false);
      }
    };

    fetchUserInfo();
    const dataQuestion = crosswordQuestions;
    
    setScore(0);
    setCorrectAnswers({});
    setIsDone(false);
    setReactPopup(null);
    fetchUserInfo();

    const handleFocus = (event) => {
      if (event.target.tagName === "INPUT") {
        event.target.blur();
      }
    };
  
    const crosswordGrid = document.querySelector(".no-keyboard-crossword");
    crosswordGrid && crosswordGrid.addEventListener("focus", handleFocus, true);
  
    return () => {
      crosswordGrid && crosswordGrid.removeEventListener("focus", handleFocus, true);
    };
  }, []);

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  const handleClueClick = (direction, number) => {
    const clue = questions[direction][number];
    const shuffledOptions = shuffleArray([...clue.options]);
    setSelectedClue({ ...clue, options: shuffledOptions, number, direction });
  };

  const closePopup = () => setSelectedClue(null);

  const closePopupTutorial = () => setPopupTutorial(false);

  const handleCloseLevel = () => setPopupLevel(false);

  const handleFillAnswer = (answerIndex) => {
    if (selectedClue && crosswordRef.current) {
      closePopup();
      const answer = selectedClue.options[answerIndex];
      const { row, col, direction } = selectedClue;
      const cells = answer.split('');

      cells.forEach((char, index) => {
        const cellRow = direction === 'across' ? row : row + index;
        const cellCol = direction === 'across' ? col + index : col;
        crosswordRef.current.setGuess(cellRow, cellCol, char);
      });
    }
  };

  const clearAnswer = () => {
    if (selectedClue && crosswordRef.current) {
      closePopup();
      const { row, col, direction } = selectedClue;
      const cells = selectedClue.answer.split('');

      cells.forEach((char, index) => {
        const cellRow = direction === 'across' ? row : row + index;
        const cellCol = direction === 'across' ? col + index : col;
        crosswordRef.current.setGuess(cellRow, cellCol, '');
      });

      const answerKey = `${direction}-${selectedClue.number}`;
      if (correctAnswers[answerKey] !== undefined) {
        setCorrectAnswers(prev => {
          const newCorrectAnswers = { ...prev };
          delete newCorrectAnswers[answerKey];
          return newCorrectAnswers;
        });
        if (correctAnswers[answerKey]) {
          setScore(score - 1);
        }
      }
    }
  };

  const handleComplete = (direction, number, correct) => {
    const answerKey = `${direction}-${number}`;
    
    if (correct) {
      setReactPopup('happy')
      setScore(score + 1);
      setTimeout(() => {
        setReactPopup(null);
      }, 1600);
    } else {
      setReactPopup('sad');
      // Hanya kurangi skor jika jawaban sudah dijawab sebelumnya dan salah lagi
      if (correctAnswers[answerKey] !== false) {
        setScore(score > 0 ? score - 1 : 0);
      }
      setTimeout(() => {
        setReactPopup(null);
      }, 1600);
    }
  
    setCorrectAnswers(prev => ({ ...prev, [answerKey]: correct }));
  };

  if (isDone) {
    const storeNewScore = async () => {
      try {
        const userId = userInfo.id;
        const scoree = (Object.values(correctAnswers).filter(value => value === true).length / (Object.keys(questions.across).length + Object.keys(questions.down).length)) * 100;

        const response = await api.post('/api/post-tts', 
          { user_id:userId, set:level, score:scoree, completed: scoree >= 80},
          {
            headers: getAuthHeader()
        });

        const previousData = JSON.parse(localStorage.getItem('user_data'));
        if (!previousData) {
          navigate('/login');
        }
                    //atur nama gamenya di bawah ini
        previousData.tts[level].pass = scoree >= 80; //set threshold KKM di sini
        previousData.tts[level].score = scoree; //set score baru di sini
        localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

        console.log('User Info:', response.data);
          
      } catch (error) {
          console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
      }
    }
    storeNewScore();
  }

  return (
    <Platform>
      <div className="flex flex-col px-0 py-0 bg-matahati-primary-300 text-white h-full relative">
        {isDone ? (
          <ResultComponent 
            origin={'/game/crossword'}
            score={(Object.values(correctAnswers).filter(value => value === true).length / (Object.keys(questions.across).length + Object.keys(questions.down).length)) * 100}
            correct={Object.values(correctAnswers).filter(value => value === true).length}
            incorrect={(Object.keys(questions.across).length + Object.keys(questions.down).length) - Object.values(correctAnswers).filter(value => value === true).length}
          />
        ) : (
          <div className="w-full h-full px-8 py-6">
            {popupTutorial && (
              <PopupTutorial
                title="Teka-Teki Silang"
                description="Pemain harus mengisi kotak teka-teki dengan kata yang tepat berdasarkan petunjuk yang diberikan."
                buttonText="Play"
                handleClose={closePopupTutorial}
                handleAction={closePopupTutorial}
              />
            )}
            {popupLevel && (
              <ChooseLevel 
                handleClose={handleCloseLevel}
                gameName={'tts'}
                questionsData={crosswordQuestions}
                questionState={setQuestions}
              />
            )}
            {reactPopup === 'happy' && (
              <PopupReaction isCorrect={true} />
            )}
            {reactPopup === 'sad' && (
              <PopupReaction isCorrect={false} />
            )}
            <BackButton title={'Teka-Teki Silang'} />
            {questions && (
              <div className="relative flex flex-col gap-2">
                <CrosswordProvider 
                  data={questions} 
                  ref={crosswordRef}
                  theme={{ 
                    allowNonSquare: true,
                    columnBreakpoint: '9999px',
                    gridBackground: '#eba7b8',
                    cellBackground: '#fff',
                    cellBorder: '#E37494',
                    textColor: '#862c4a',
                    numberColor: '#862c4a',
                    focusBackground: '#f6dbe1',
                    highlightBackground: '#eba7b8',
                    correct: '#a5b959'
                  }}
                  gridProps={{ readOnly: true }}
                  onAnswerComplete={handleComplete}
                >
                  <div className="w-full no-keyword-crossword rounded-lg shadow-lg shadow-matahati-primary-500 p-2 py-4 z-0 bg-matahati-primary-200 relative" style={{ pointerEvents: 'none' }}>
                    <CrosswordGrid />
                  </div>
                </CrosswordProvider>
                <div className="w-full flex flex-col gap-4 p-4 rounded-lg bg-matahati-primary-200 shadow-lg my-8  text-matahati-primary-500 shadow-matahati-primary-500">
                  <div className="flex flex-col w-full gap-2">
                    <p className="font-bold text-center">Pilih Pertanyaan</p>
                    <p className="font-bold">Mendatar:</p>
                    <div className="flex w-full gap-x-2">
                      {Object.keys(questions.across).map((number) => {
                        const answerKey = `across-${number}`;
                        const isCorrect = correctAnswers[answerKey] === true;
                        const isIncorrect = correctAnswers[answerKey] === false;
                        return (
                          <button
                            key={answerKey}
                            onClick={() => handleClueClick("across", number)}
                            className={`rounded-lg w-12 h-12 font-bold text-xl  shadow-inner shadow-neutral-700
                              ${isCorrect ? 'bg-matahati-secondary-200 hover:bg-matahati-secondary-300 text-white' : 
                                isIncorrect ? 'bg-matahati-primary-500 hover:bg-matahati-primary-300 text-white' : 
                                'bg-white text-matahati-primary-500 hover:bg-matahati-primary-100'}
                            `}
                          >
                            {number}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    <p className="font-bold">Menurun:</p>
                    <div className="flex w-full gap-x-2">
                      {Object.keys(questions.down).map((number) => {
                        const answerKey = `down-${number}`;
                        const isCorrect = correctAnswers[answerKey] === true;
                        const isIncorrect = correctAnswers[answerKey] === false;
                        return (
                          <button
                            key={answerKey}
                            onClick={() => handleClueClick("down", number)}
                            className={`rounded-lg w-12 h-12 font-bold text-xl shadow-inner shadow-neutral-700 
                              ${isCorrect ? 'bg-matahati-secondary-200 hover:bg-matahati-secondary-300 text-white' : 
                                isIncorrect ? 'bg-matahati-primary-500 hover:bg-matahati-primary-300 text-white' : 
                                'bg-white text-matahati-primary-500 hover:bg-matahati-primary-100'}
                            `}
                          >
                            {number}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <button onClick={() => setIsDone(true)}
                    className="cursor-pointer px-4 py-2 rounded-full bg-matahati-secondary-200 hover:bg-matahati-secondary-300 shadow-inner shadow-neutral-700 text-white text-xs font-bold"
                  >
                    Selesai/Menyerah
                  </button>
                </div>
              </div>
            )}
            {selectedClue && (
              <div className="fixed flex items-center justify-center w-full top-0 left-0 bg-black h-full bg-opacity-25">
                <div className="bg-white text-matahati-primary-400 rounded-xl px-8 py-4 flex flex-col gap-4 h-fit w-72 relative">
                  <button onClick={closePopup} className="absolute top-2 right-2 text-3xl">
                    <IoCloseCircleSharp />
                  </button>
                  <h3 className="font-bold">
                    {selectedClue.direction === 'across' ? 'Mendatar' : 'Menurun'} No. {selectedClue.number}
                  </h3>
                  <p>{selectedClue.clue}</p>
                  <div className="flex w-full items-center justify-center gap-2 flex-col">
                    <div className="flex gap-2 w-full">
                      {selectedClue.options.map((option, index) => (
                        <p
                          key={index}
                          onClick={() => handleFillAnswer(index)}
                          className="cursor-pointer w-full bg-matahati-primary-400 px-4 py-2 text-white font-bold rounded-lg text-center"
                        >
                          {option}
                        </p>
                      ))}
                    </div>
                    <button onClick={clearAnswer} className="border-2 rounded-lg px-4 py-2 font-bold border-matahati-primary-400 w-full">
                      Hapus Jawaban
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Platform>
  );  
}

export default CrosswordGame;
