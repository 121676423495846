import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Platform from "./platform";
import BackButton from "../components/BackButton";
import ResultComponent from "../components/Result"; // Import komponen ResultComponent
import { PopupTutorial, PopupReaction, ChooseLevel } from "../components/Popup";
import backgroundImage from "../assets/background_with_object.png";
import questionData from "../data/family100Data";
import { FaCirclePlay } from "react-icons/fa6";
import { api, getAuthHeader } from "../controllers/getUserInfo";
import fetchPackage from "../controllers/getLevelToPlay";
import set1_1 from "../assets/sounds/family100/set1_1.mp3";
import set1_2 from "../assets/sounds/family100/set1_2.mp3";
import set1_3 from "../assets/sounds/family100/set1_3.mp3";
import set1_4 from "../assets/sounds/family100/set1_4.mp3";
import set1_5 from "../assets/sounds/family100/set1_5.mp3";
import set2_1 from "../assets/sounds/family100/set2_1.mp3";
import set2_2 from "../assets/sounds/family100/set2_2.mp3";
import set2_3 from "../assets/sounds/family100/set2_3.mp3";
import set2_4 from "../assets/sounds/family100/set2_4.mp3";
import set2_5 from "../assets/sounds/family100/set2_5.mp3";
import set3_1 from "../assets/sounds/family100/set3_1.mp3";
import set3_2 from "../assets/sounds/family100/set3_2.mp3";
import set3_3 from "../assets/sounds/family100/set3_3.mp3";
import set3_4 from "../assets/sounds/family100/set3_4.mp3";
import set3_5 from "../assets/sounds/family100/set3_5.mp3";
import set4_1 from "../assets/sounds/family100/set4_1.mp3";
import set4_2 from "../assets/sounds/family100/set4_2.mp3";
import set4_3 from "../assets/sounds/family100/set4_3.mp3";
import set4_4 from "../assets/sounds/family100/set4_4.mp3";
import set4_5 from "../assets/sounds/family100/set4_5.mp3";
import set5_1 from "../assets/sounds/family100/set5_1.mp3";
import set5_2 from "../assets/sounds/family100/set5_2.mp3";
import set5_3 from "../assets/sounds/family100/set5_3.mp3";
import set5_4 from "../assets/sounds/family100/set5_4.mp3";
import set5_5 from "../assets/sounds/family100/set5_5.mp3";

const audioFolder = "../assets/sounds/family100/";

const QuestionPage = () => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [score, setScore] = useState(0);
  const [totalTime, setTotalTime] = useState(25);
  const [gameOver, setGameOver] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [popupLevel, setPopupLevel] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [packageNum, setPackageNum] = useState(0);
  const [questionPackage, setQuestionPackage] = useState(0);
  const randomSet = questionData.sets[packageNum];
  const sounds = {
    "set1_1.mp3": set1_1,
    "set1_2.mp3": set1_2,
    "set1_3.mp3": set1_3,
    "set1_4.mp3": set1_4,
    "set1_5.mp3": set1_5,
    "set2_1.mp3": set2_1,
    "set2_2.mp3": set2_2,
    "set2_3.mp3": set2_3,
    "set2_4.mp3": set2_4,
    "set2_5.mp3": set2_5,
    "set3_1.mp3": set3_1,
    "set3_2.mp3": set3_2,
    "set3_3.mp3": set3_3,
    "set3_4.mp3": set3_4,
    "set3_5.mp3": set3_5,
    "set4_1.mp3": set4_1,
    "set4_2.mp3": set4_2,
    "set4_3.mp3": set4_3,
    "set4_4.mp3": set4_4,
    "set4_5.mp3": set4_5,
    "set5_1.mp3": set5_1,
    "set5_2.mp3": set5_2,
    "set5_3.mp3": set5_3,
    "set5_4.mp3": set5_4,
    "set5_5.mp3": set5_5,
  };

  const audioRef = useRef(null);

  const getUserInfo = async () => {
    try {
      const response = await api.get("/api/user", {
        headers: getAuthHeader(),
      });
      console.log("User Info:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Failed to fetch user info:",
        error.response ? error.response.status : error.message
      );
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  if (gameOver) {
    const storeNewScore = async () => {
      try {
        const userId = userInfo.id;
        const accuracy = ((score / 200) * 100).toFixed(0);

        const response = await api.post(
          "/api/post-family-100",
          {
            user_id: userId,
            set: questionPackage,
            score: accuracy,
            completed: accuracy >= 80,
          },
          {
            headers: getAuthHeader(),
          }
        );
        const previousData = JSON.parse(localStorage.getItem("user_data"));
        console.log(previousData);
        if (!previousData) {
          navigate("/login");
        }
        previousData.family100[questionPackage].pass = accuracy >= 80; //set threshold KKM di sini
        previousData.family100[questionPackage].score = score; //set score baru di sini
        localStorage.setItem("user_data", JSON.stringify(previousData)); //simpan perubahan ke localstorage

        console.log("User Info:", response.data);
      } catch (error) {
        console.error(
          "Failed to fetch user info:",
          error.response ? error.response.status : error.message
        );
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      }
    };
    storeNewScore();
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userData = await getUserInfo();
      setUserInfo(userData);
      const set = await fetchPackage("family100", "/game/family100");
      if (set) {
        setQuestionPackage(set);
        setQuestions(dataQuestion.sets[set - 1].questions);
        console.log(questions);
      } else {
        setPopupLevel(true);
        setShowPopup(false);
      }
    };
    fetchUserInfo();
    const dataQuestion = questionData;
    setScore(0);
    setCurrentQuestionIndex(0);
    setCorrectAnswersCount(0);
  }, []);

  const goToNextQuestion = useCallback(() => {
    setSelectedAnswer(null);
    setIsAnswered(false);

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setGameOver(true);
    }
  }, [currentQuestionIndex, questions]);

  const playAudio = async () => {
    try {
      if (audioRef.current) {
        const soundFileName = questions[currentQuestionIndex]?.sound;
        console.log(soundFileName);
        if (soundFileName) {
          const audioSrc = sounds[soundFileName];
          audioRef.current.src = audioSrc;
          console.log("Playing audio from:", audioSrc); // Confirm path
          await audioRef.current.play();
        } else {
          console.log("Sound file not found for this question.");
        }
      }
    } catch (error) {
      console.log("Audio play error:", error);
    }
  };

  useEffect(() => {
    if (!timerStarted || gameOver) {
      return;
    }

    if (totalTime === 0) {
      setGameOver(true);
      return;
    }

    const interval = setInterval(() => {
      setTotalTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerStarted, totalTime, gameOver]);

  const handleAnswerClick = (option) => {
    setSelectedAnswer(option);
    setIsAnswered(true);

    if (option.option === questions[currentQuestionIndex].correctAnswer) {
      setScore((prevScore) => prevScore + option.score);
      setCorrectAnswersCount((prevCount) => prevCount + 1);
    } else {
      setScore((prevScore) => prevScore + option.score);
    }

    setTimeout(() => {
      goToNextQuestion();
    }, 1000);
  };

  const startGame = () => {
    setShowPopup(false);
    setTimerStarted(true);
  };

  const handleCloseLevel = () => {
    setPopupLevel(false);
  };

  const accuracy = ((score / 200) * 100).toFixed(0);
  const incorrectAnswers = questions.length - correctAnswersCount;

  return (
    <Platform>
      <div
        className="flex items-center justify-center h-screen"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundColor: "#FEE2E2",
        }}
      >
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white text-center p-6 rounded-lg max-w-xs w-full shadow-lg relative">
              <button
                onClick={() => navigate("/island")}
                className="absolute top-3 right-3 text-red-500 font-bold text-xl"
              >
                ✕
              </button>
              <h2 className="text-2xl font-bold text-pink-600">Family 100</h2>
              <p className="text-gray-600 mt-2 mb-4">
                Pemain harus memilih jawaban yang benar dari beberapa pilihan
                yang diberikan. Pemain harus memilih jawaban dalam batas waktu
                yang ditentukan.
              </p>
              <button
                onClick={startGame}
                className="bg-green-500 text-white font-bold py-2 px-8 rounded-full hover:bg-green-600"
              >
                Main
              </button>
            </div>
          </div>
        )}
        {popupLevel && (
          <ChooseLevel
            handleClose={handleCloseLevel}
            gameName={"family100"}
            questionsData={questionData}
            questionState={setQuestions}
          />
        )}

        <div className="w-full h-full flex flex-col justify-center max-w-md relative">
          {!gameOver && (
            <>
              <div className="absolute top-0 w-full p-8">
                <BackButton to={"/"} title={"Family100"} />
                <div className="w-full bg-gray-300 h-2 rounded-full mb-4">
                  <div
                    className="bg-green-500 h-2 rounded-full"
                    style={{
                      width: `${
                        ((currentQuestionIndex + 1) / questions.length) * 100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            </>
          )}

          {gameOver ? (
            <ResultComponent
              origin="/game/family100"
              score={accuracy}
              correct={"5"}
              incorrect={"0"}
            />
          ) : (
            questions && (
              <div className="ml-6 mr-6">
                <div className="text-center text-lg font-bold text-white mb-4">
                  Waktu tersisa: {totalTime} detik
                </div>
                <div className="w-full max-w-md bg-white rounded-lg text-pink-800 font-bold p-4 mb-4 flex items-center justify-between space-x-4">
                  <h1 className="text-xl font-bold">
                    {questions[currentQuestionIndex]?.question}
                  </h1>
                  <button
                    onClick={playAudio}
                    className="bg-matahati-primary-100 text-white font-bold p-2 rounded-full"
                  >
                    <FaCirclePlay className="text-matahati-primary-300 h-8 w-8 hover:bg-matahati-primary-200 rounded-full" />
                  </button>
                </div>

                <div className="space-y-4">
                  {questions[currentQuestionIndex]?.options.map(
                    (option, index) => (
                      <button
                        key={index}
                        onClick={() => handleAnswerClick(option)}
                        className={`w-full py-2 px-4 rounded-lg text-white font-semibold 
                  ${
                    isAnswered
                      ? option.option ===
                        questions[currentQuestionIndex].correctAnswer
                        ? "bg-green-700"
                        : option === selectedAnswer
                        ? "bg-green-500"
                        : "bg-gray-400"
                      : "bg-pink-500 hover:bg-pink-600"
                  }`}
                        disabled={isAnswered}
                      >
                        {option.option}{" "}
                        {isAnswered ? `- ${option.score} Poin` : ""}
                      </button>
                    )
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <audio ref={audioRef} />
      </div>
    </Platform>
  );
};

export default QuestionPage;
