const fetchPackage = async (gameName, origin) => {
  try {
    console.log('kontol 2: ')
    const previous_level = localStorage.getItem(`level_${origin}_to_play`);

    if (previous_level) {
      console.log(parseInt(previous_level))
      return previous_level;
    }

    const userData = JSON.parse(localStorage.getItem('user_data'));
    
    if (!userData || !userData[gameName]) {
      throw new Error(`${gameName} tidak ditemukan`);
    }
    const gameData = userData[gameName];
    const firstLevelWithPassFalseKey = Object.keys(gameData).find(key => gameData[key].pass === false);

    if (firstLevelWithPassFalseKey) {
      localStorage.setItem(`level_${origin}_to_play`, firstLevelWithPassFalseKey);
      console.log(`KOntol1: ${firstLevelWithPassFalseKey}`)
      return firstLevelWithPassFalseKey; // Kembalikan key (ID) dari level pertama dengan pass === false
    } else {
      console.log(`Tidak ada level dengan pass === false di game ${gameName}`);
      return null;
    }

  } catch (error) {
    console.error("Error fetching package:", error);
    return null;
  }
}; 

export default fetchPackage;