import React from "react";

const HeartBar = ({ remain, total }) => {
  const percentage = Math.min((remain / total) * 100, 100);

  return (
    <div className="flex items-center w-full max-w-md rounded-lg bg-matahati-primary-400 shadow-inner shadow-neutral-700 p-4">
      <div className="flex flex-col items-center w-full">
        <p className="text-xs mb-1 text-white font-bold">Pastikan tetap berwarna hijau!</p>
        <div className="relative w-full h-2 bg-slate-50 rounded-full overflow-hidden z-0">
          <div
            className={`absolute h-full transition-all duration-300 ${percentage < 67 ? 'bg-green-400' : percentage < 78 ? 'bg-orange-400' : 'bg-red-400'}`}
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
    </div>
  );
}

export default HeartBar;