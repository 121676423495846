import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Photo from '../assets/obat.jpg';
import { BsArrowLeftCircleFill, BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab6Stage2 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab6Stage2 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:6, stage:2},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[6][2] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/6/3');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Cara Mengendalikan Hipertensi</h2>
          <button onClick={() => navigate('/island')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">2. Atasi Penyakit dengan Pengobatan yang Tepat dan Teratur</h3>
          
          <div className="text-gray-700 text-lg">
          <p>Apakah penderita hipertensi harus minum obat?</p>
          <img
            src={Photo}
            alt="Pressure Meter"
            className="w-full my-4 h-64 object-cover"
          />
          <ul className='mx-8' style={{listStyleType: 'circle'}}>
            <li>Obat adalah hal yang vital untuk penderita
            hipertensi. </li>
            <li>Penderita <b>wajib minum obat setiap hari</b>
dengan dosis sesuai anjuran dokter secara
rutin dan terus menerus sepanjang
hidupnya. </li>
            <li>Obat <b>harus diminum</b> meskipun badan atau
            tekanan darah sudah menjadi baik.</li>
            <li><b>Jangan beli obat sendiri</b> tanpa resep dokter</li>
            <li>Tantangannya, kebanyakan orang tidak
meminum obat lagi jika tekanan darah
sudah turun atau badan sudah enak. <b>Ini hal
yang salah</b>
</li>
            <li><b>Jangan lupa minum obat.</b></li>
            <li><b>Jangan lupa kontrol ke dokter</b> untuk
            mendapatkan obat sesuai resepnya. </li>
          </ul>
          {/* Tambahkan peringatan 'Jangan Putus Kontrol!' */}
            <div className="bg-red-500 text-white p-4 rounded-lg mt-4">
                Jangan Putus Obat!
                </div>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
        <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/6/1')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab6Stage2}>
            {lanjut}
          </button>
          </div>
      </div>
    </Platform>
  );
};

export default Bab6Stage2;
