import React from "react";
import Firework from "../assets/firework.png"
import { useNavigate } from "react-router-dom";

const ResultComponent = ({
  origin,
  score,
  correct,
  incorrect,
}) => {
  const navigate = useNavigate();

  const handleReplay = () => {
    navigate(origin)
  }

  const handleNextLevel = () => {
    localStorage.removeItem(`level_${origin}_to_play`);
    navigate(origin);
  }
  
  const handleBackToIsland = () => {
    localStorage.removeItem(`level_${origin}_to_play`);
    navigate('/island');
  }

  return (
    <div className="flex flex-col items-center justify-center gap-4 w-full h-full bg-white px-12 text-center text-gray-800">
      <div className="mb-4">
        <img
          src={Firework}
          alt="Object"
          className="w-full h-48 object-contain rounded-lg"
        />
      </div>
      <div className="flex flex-col gap-0">
        <div className="text-5xl font-black mb-2 text-matahati-gray-1">Selamat</div>
        <p className="text-matahati-gray-1 text-lg mb-4">Kamu berhasil menyelesaikan Kuis</p>
      </div>
      <div className="flex flex-col w-full items-center space-y-2 mb-4">
        <div className="
          bg-pink-100 w-full py-4 px-4 rounded-lg shadow-inner shadow-neutral-500
          text-matahati-primary-500 font-bold text-xl
        ">
          <p className="text-lg font-semibold">Skor</p>
          <span className="text-4xl font-black">
          {(Number(score) || 0).toFixed(0)}%
          </span>
        </div>
        <div className="flex w-full space-x-2">
          <div className="
            bg-matahati-primary-500 text-white font-black 
            py-2 px-4 rounded-lg w-full shadow-inner shadow-neutral-500
          ">
            <p className="text-lg font-semibold">Salah</p>
            <span className="text-3xl">{incorrect}</span>
          </div>
          <div className="
            bg-matahati-secondary-300 text-white font-black 
            py-2 px-4 rounded-lg w-full shadow-inner shadow-neutral-500
          ">
            <p className="text-lg font-semibold">Benar</p>
            <span className="text-3xl">{correct}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full space-y-2">
        {score < 80 && (
          <p>Capai skor minimal 80 untuk ke level berikutnya</p>
        )}
        <div className="flex w-full gap-2">
          <a
            href={origin}
            onClick={handleReplay}
            className="w-full flex items-center justify-center bg-matahati-primary-300 shadow-inner shadow-neutral-600 text-white font-bold py-3 rounded-lg text-center text-sm px-2 hover:bg-matahati-primary-400 hover:text-white"
          >
            Ulangi game
          </a>
          <a
            href="/edukasi/1/1"
            onClick={handleBackToIsland}
            className="w-full flex items-center justify-center bg-matahati-primary-300 shadow-inner shadow-neutral-600 text-white font-bold py-3 rounded-lg text-center text-sm px-2 hover:bg-matahati-primary-400 hover:text-white"
          >
            Belajar
          </a>
          {score >= 80 && (
            <a
              href={origin}
              onClick={handleNextLevel}
              className="w-full flex items-center justify-center bg-matahati-primary-300 shadow-inner shadow-neutral-600 text-white font-bold py-3 rounded-lg text-center text-sm px-2 hover:bg-matahati-primary-400 hover:text-white"
            >
              Level Berikutnya
            </a>
          )}
        </div>
        <a
          href="/dashboard"
          className="w-full bg-matahati-primary-400 shadow-inner shadow-neutral-600 text-white font-bold py-3 rounded-full text-center hover:bg-matahati-primary-500"
        >
          Kembali ke halaman awal
        </a>
      </div>
    </div>
  );
};

export default ResultComponent;
