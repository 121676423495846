import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Photo from '../assets/gambar.png';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { api, getAuthHeader } from "../../../controllers/getUserInfo";
import { useNavigate } from 'react-router-dom';

const Bab1Stage1 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab1 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:1, stage:1},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[1][1] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/1/2');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <a href='/island' className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" />
          </a>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Hipertensi</h2>
          <a href='/edukasi/2/1' className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </a>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Apakah Pengertian Hipertensi?</h3>
          <img
            src={Photo}
            alt="Pressure Meter"
            className="w-full my-4 h-64 object-cover"
          />
          <p className="text-gray-700 text-lg">
          Tekanan darah dapat dikategorikan menjadi tekanan darah sistolik dan diastolik. 
Sistolik dalam pengukuran tekanan darah ditunjukkan dengan nilai atas pada hasil pengukuran.<br/><br/>
Diastolik dalam pengukuran tekanan darah ditunjukkan dengan nilai bawah pada hasil pengukuran. 
Hipertensi atau tekanan darah tinggi adalah keadaan peningkatan pada tekanan darah sistolik &gt;140 mmHg atau tekanan darah diastolik &gt;90 mmHg.<br/><br/>
Tekanan darah sistolik adalah tekanan yang ditimbulkan saat jantung berdetak  untuk memompa atau mendorong darah ke seluruh tubuh. 
Angka tekanan darah sistolik yang normal adalah sekitar 120 mmHg. Tekanan darah diastolik adalah tekanan darah yang ditimbulkan saat jantung tidak beristirahat. 
Angka tekanan darah diastolik sekitar 90 mmHg
          </p>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-end p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab1}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab1Stage1;
