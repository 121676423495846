import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Island from "./Island";
import Platform from "../platform";
import Hati from "./assets/PULAU/Pulau Hati.png";
import Otak from "./assets/PULAU/Pulau Otak.png";
import Jantung from "./assets/PULAU/Pulau Jantung.png";
import Paru from "./assets/PULAU/Pulau Paru.png";
import Ginjal from "./assets/PULAU/Pulau Ginjal.png";
import Lambung from "./assets/PULAU/Pulau Lambung.png";
import SeaPhoto from "./assets/PULAU/Seaonly.png";
import PlayerPhoto from "./assets/logo.png";
import { BsArrowLeftCircleFill, BsCheckCircleFill } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { api, getAuthHeader } from "../../controllers/getUserInfo";

function StageSelection() {
  const navigate = useNavigate();
  const [percent, setPercent] = useState(0);
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
    getProgress();
  }, []);

  const getProgress = async () => {
    try {
      const response = await api.get('/api/get-progress',
        {
          headers: getAuthHeader()
      });

      console.log('User Info:', response.data);
      setPercent(response.data.total);
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }

  const stages = [
    {
      id: 1,
      name: "Hati",
      imgSrc: Hati,
      route: "/game/crossword",
      unlocked: true,
      percentage: (Object.values(JSON.parse(localStorage.getItem("user_data")).tts).filter((item) => item.pass == true).length) / (Object.values(JSON.parse(localStorage.getItem("user_data")).tts).length) * 100,
    },
    {
      id: 2,
      name: "Otak",
      imgSrc: Otak,
      route: "/game/guess-the-word",
      unlocked: true,
      percentage: (Object.values(JSON.parse(localStorage.getItem("user_data")).tebak_kata).filter((item) => item.pass == true).length) / (Object.values(JSON.parse(localStorage.getItem("user_data")).tebak_kata).length) * 100,
    },
    {
      id: 3,
      name: "Jantung",
      imgSrc: Jantung,
      route: "/game/guess-the-picture",
      unlocked: true,
      percentage: (Object.values(JSON.parse(localStorage.getItem("user_data")).tebak_gambar).filter((item) => item.pass == true).length) / (Object.values(JSON.parse(localStorage.getItem("user_data")).tebak_gambar).length) * 100,
    },
    {
      id: 4,
      name: "Paru-paru",
      imgSrc: Paru,
      route: "/game/survey100",
      unlocked: true,
      percentage: (Object.values(JSON.parse(localStorage.getItem("user_data")).survey100).filter((item) => item.pass == true).length) / (Object.values(JSON.parse(localStorage.getItem("user_data")).survey100).length) * 100,
    },
    {
      id: 5,
      name: "Ginjal",
      imgSrc: Ginjal,
      route: "/game/family100",
      unlocked: true,
      percentage: (Object.values(JSON.parse(localStorage.getItem("user_data")).family100).filter((item) => item.pass == true).length) / (Object.values(JSON.parse(localStorage.getItem("user_data")).family100).length) * 100,
    },
    {
      id: 6,
      name: "Lambung",
      imgSrc: Lambung,
      route: "/game/true-false",
      unlocked: true,
      percentage: (Object.values(JSON.parse(localStorage.getItem("user_data")).mitos_fakta).filter((item) => item.pass == true).length) / (Object.values(JSON.parse(localStorage.getItem("user_data")).mitos_fakta).length) * 100,
    },
  ];

  const handlePlayClick = (route) => {
    navigate(route);
  };
  const handleBackClick = () => {
    navigate("/dashboard");
  };

  return (
    <Platform>
      <div
        className="relative h-full bg-matahati-primary-400"
      >
        <div className="flex px-8 py-6 items-center gap-4 bg-white rounded-b-lg shadow-lg">
          <IoIosArrowBack
            className="text-matahati-primary-400 text-3xl cursor-pointer"
            onClick={handleBackClick}
          />
          <div className="flex flex-col items-center">
            <img
              src={PlayerPhoto}
              alt="Profile"
              className="w-12 h-auto rounded-full shadow-md"
            />
          </div>
          <div className="flex flex-col items-start">
            <div className="flex items-center">
              <BsCheckCircleFill className="text-matahati-secondary-200" />
              <span className="ml-2 text-matahati-primary-400 font-semibold">{percent.toFixed(2)}% dipelajari</span>
            </div>
            <div className="w-56 h-2 bg-matahati-primary-100 rounded-full mt-1 overflow-hidden">
              <div className={`h-full ${percent < 33 ? 'bg-red-500' : (percent < 66 ? 'bg-yellow-500' : 'bg-green-500')} rounded-full`} style={{width: `${percent.toFixed(0)}%`}}></div>
            </div>
          </div>
        </div>
        <h2 className="text-2xl font-bold text-center text-white mt-8 underline">
          Pilih Organmu
        </h2>
        <p className="text-sm font-semibold text-center text-white -mb-4 px-8">
          Pulau besar berisi <span className="italic">game</span>, pulau kecil berisi materi belajar
        </p>
        <div className="rounded-lg shadow-lg m-8 overflow-y-auto hide-scrollbar" style={{height: 500}}>
        <div
          className={`flex flex-wrap justify-center gap-16 p-8`}
          style={{
          backgroundImage: `url(${SeaPhoto})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'scroll'
         }}
        >
          {stages.map((stage) => (
            <Island
              key={stage.id}
              id={stage.id}
              name={stage.name}
              imgSrc={stage.imgSrc}
              unlocked={stage.unlocked}
              onPlay={() => handlePlayClick(stage.route)}
              percentage={stage.percentage}
            />
          ))}
        </div>
        </div>
      </div>
    </Platform>
  );
}

export default StageSelection;
