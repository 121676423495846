import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Umur from '../assets/umur.png';
import Kelamin from '../assets/kelamin.jpg';
import Genetik from '../assets/genetik.png';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab2Stage1 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab2Stage1 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:2, stage:1},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[2][1] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/2/2');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Faktor Resiko Hipertensi</h2>
          <button onClick={() => navigate('/edukasi/3/1')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 pt-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Faktor yang Tidak Dapat Diubah</h3>
          
          <div className="text-gray-700 text-lg">
          <ul className='mx-8 my-4' style={{listStyleType: 'circle'}}>
                <li className='mb-4'>
                    <b>Umur</b>
                    <br/>
                    <img
            src={Umur}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
                    Apabila umur seseorang bertambah, maka akan menyebabkan bertambahnya 
tekanan darah pula. Pada usia yang semakin tua akan terjadi kerusakan pada 
ppembuluh darah sehingga tidak elastis lagi yang menimbulkan tekanan
darah semakin tinggi. Pada umumnya tekanan darah tinggu mulai usia 45-55 
tahun dan akan semakin meingkat pada usia 55-64 tahun.
                </li>
                <li className='mb-4'>
                    <b>Jenis Kelamin</b>
                    <br/>
                    <img
            src={Kelamin}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Pada umumnya tekanan darah pria lebih tinggi dibanding tekanan darah 
wanita. Pada laki-laki memiliki faktor resiko lebih dibanding Perempuan
dikarenakan adanya pengaruh gaya hidup yang tidak sehat dibandingkan 
dengan perempuan. Hipertensi pada Perempuan sering dialami setelah
menopause atau pada saat tidak lagi menstruasi/datang bulan.
                </li>
                <li>
                    <b>Genetik</b>
                    <br/>
                    <img
            src={Genetik}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Hipertensi itu genetik yang tidak bisa diubah. Seseorang besar akan terkena
hipertensi jika orang tuanya penderita hipertensi. Factor genetik ini 
membawa sifat perubahan metabolism pengaturan garam dan hormon dalam
tubuh. Akibatnya akan menyebabkan resiko lebih tinggi terkena hipertensi
pada orang yang punya keturunan hipertensi dari keluarganya.

                </li>
            </ul>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/1/3')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab2Stage1}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab2Stage1;
