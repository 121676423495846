import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab6Stage4 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab6Stage4 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:6, stage:4},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[6][4] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/6/5');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Cara Mengendalikan Hipertensi</h2>
          <button onClick={() => navigate('/island')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">4. Upayakan Olahraga Teratur</h3>
          <div className="text-gray-700 text-lg mb-6">
            <b>Mengapa penderita hipertensi harus olah raga?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li>Olah raga sangat penting untuk membuat pembuluh darah tidak kaku dan jantung lebih mudah memompa darah keseluruh tubuh , sehingga hipertensi bisa turun.</li>
              <li>Selain itu, olah raga akan membakar lemak tubuh sehingga berat badan bisa ideal dan pembuluh darah  menjadi bersih dari lemak.</li>
              <li>Olah raga teratur bisa menurunkan tekanan darah 4 – 8 mmHg</li>
            </ul>
          </div>
          <div className="text-gray-700 text-lg mb-6">
            <b>Berapa lama berolah raga?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li>Selama 30  menit per hari sebanyak 4 -  5 kali setiap minggu.</li>
            </ul>
          </div>
          <div className="text-gray-700 text-lg mb-6">
            <b>Apa jenis aktivitas  atau olah raga yang bisa dilakukan?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li><span className='font-semibold italic mr-1 underline'>Berjalan Kaki.</span>Berjalan kaki selama 30 menit setiap hari tanpa berhenti akan menurunkan tekanan darah.</li>
              <li><span className='font-semibold italic mr-1 underline'>Aerobik.</span>Gerakan aerobik merupakan gerakan yang dilakukan berulang-ulang. Gerakan aerobik yang dilakukan secara teratur akan menurunkan tekanan darah 8 mmHg.</li>
              <li><span className='font-semibold italic mr-1 underline'>Olah raga dengan tahanan yang dinamis (dynamic resistance).</span>
              Gerakan ini dapat dilakukan dengan menggerakkan sendi. Gerakan ini dapat menurunkan tekanan darah 4 mmHg.</li>
              <li><span className='font-semibold italic mr-1 underline'>Isometrik resistance.</span>
              Gerakan ini dapat dilakukan dengan tahanan tanpa menggerakkan sendi. Gerakan ini dapat menurunkan tekanan darah hingga 5 mmHg.</li>
            </ul>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
        <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/6/3')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab6Stage4}>
            {lanjut}
          </button>
          </div>
      </div>
    </Platform>
  );
};

export default Bab6Stage4;
