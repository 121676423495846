import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Jantung from '../assets/berdebar.png';
import Stroke from '../assets/stroke.webp';
import Ginjal from '../assets/ginjal.png';
import Mata from '../assets/mata.png';
import Saraf from '../assets/saraf.jpg';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab4Stage1 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab4Stage1 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:4, stage:1},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[4][1] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/5/1');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Komplikasi Hipertensi</h2>
          <button onClick={() => navigate('/edukasi/5/1')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 pt-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Mengapa Hipertensi ini Harus Dikontrol Rutin?</h3>
          
          <div className="text-gray-700 text-lg">
            <p>Karena jika hipertensi tidak dikontrol maka akan menimbulkan komplikasi
            seperti :</p>
          <ul className='mx-8 my-4' style={{listStyleType: 'circle'}}>
                <li className='mb-4'>
                    <b>Penyakit Jantung</b>
                    <br/>
                    <img
            src={Jantung}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
                    Akibat tekanan darah tinggi, jantung harus bekerja lebih keras untuk 
memompa darah dan biasanya gejalanya berupa penambahan berat badan, 
kekakuan pada kaki, dan sesak napas.
                </li>
                <li className='mb-4'>
                    <b>Stroke</b>
                    <br/>
                    <img
            src={Stroke}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Menyebabkan perdarahan akibat pecahnya pembuluh darah di dalam otak.
                </li>
                <li className='mb-4'>
                    <b>Penyakit Ginjal</b>
                    <br/>
                    <img
            src={Ginjal}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Hipertensi menyebabkan aliran darah ke ginjal tidak lancar sehingga ginjal
banyak mengalami kerusakan. Komplikasi ini merupakan komplikasi yang 
serinh terjadi pada pasien hipertensi. Jika ginjal rusak maka memerlukan
hemodialisis atau cuci darah seumur hidup. 
                </li>
                <li className='mb-4'>
                    <b>Kelainan pada Mata bahkan Kebutaan</b>
                    <br/>
                    <img
            src={Mata}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Tekanan darah tinggi dapat melemahkan bahkan merusak 
pembuluh darah dibelakang mata yang dapat menyebabkan 
penglihatan kabur dan buram. Retinopati Hipertensi serta 
kebutaan dapat terjadi pada hipertensi yang tidak ke kontrol 
dengan baik. 
                </li>
                <li className='mb-4'>
                    <b>Gangguan Saraf dan Otak</b>
                    <br/>
                    <img
            src={Saraf}
            alt="Pressure Meter"
            className="w-auto my-2 h-32 object-cover"
          />
          Tekanan darah tinggi dapat menyebabkan kurangnya aliran darah
ke otak yang mengakibatkan kerusakan saraf di otak. Dapat
menimbulkan gangguan memori dan kognitif.
                </li>
            </ul>
          </div>
          {/* tambahkan peringatan dan langkah-langkah yang harus dilakukan */}
            <div className="bg-red-100 text-red-700 p-4 rounded-lg my-4">
                <p className="font-bold">SANGAT BERBAHAYA!!!</p>
                <p>Apabila Anda mengalami gejala-gejala tersebut, segera periksakan diri ke dokter.</p>
                <ul className='mx-8 my-4' style={{listStyleType: 'circle'}}>
                    <li className='mb-4'>Ayo rutin kontrol ke Puskesmas agar bisa ditangani!</li>
                    <li className='mb-4'>Jangan lupa rutin minum obat!</li>
                    <li className='mb-4'>Lakukan gaya hidup sehat!</li>
                </ul>
                </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/3/1')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab4Stage1}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab4Stage1;
