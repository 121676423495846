const synonymDict = {
    "Keturunan": ["keturunan", "genetik", "riwayat keluarga"],
    "Umur": ["umur", "usia", "lansia", "orang tua"],
    "Kurang aktivitas": ["kurang aktivitas", "kurang olahraga", "sedentari", "gaya hidup pasif"],
    "Suka makan berlemak": ["makan berlemak", "makanan berlemak", "lemak tinggi"],
    "Pusing": ["pusing", "sakit kepala", "kepala pusing"],
    "Lemes": ["lemes", "lemas", "tidak bertenaga", "kelelahan"],
    "Jantung berdebar-debar": ["jantung berdebar", "berdebar-debar", "palpitasi"],
    "Mata kabur": ["mata kabur", "penglihatan kabur", "kabur", "penglihatan tidak jelas"],
    "Minum obat": ["minum obat", "konsumsi obat", "pengobatan"],
    "Hindari makanan asin": ["hindari makanan asin", "batasi garam", "tidak makan asin"],
    "Batasi makan berlemak": ["batasi lemak", "hindari lemak", "kurangi lemak"],
    "Olahraga": ["olahraga", "aktivitas fisik", "latihan fisik"],
    "Stroke": ["stroke", "penyakit stroke", "serangan otak"],
    "Sakit ginjal": ["sakit ginjal", "penyakit ginjal", "gangguan ginjal"],
    "Sakit jantung": ["sakit jantung", "penyakit jantung", "gangguan jantung"],
    "Kebutaan": ["kebutaan", "hilang penglihatan", "butanya mata"],
    "Direbus": ["direbus", "rebus", "masakan rebus"],
    "Dikukus": ["dikukus", "kukus", "masakan kukus"],
    "Dipanggang": ["dipanggang", "panggang", "masakan panggang"],
    "Diasap": ["diasap", "asap", "masakan asap"],
    "Batasi garam 1 sendok teh": ["batasi garam", "kurangi garam", "1 sendok teh garam"],
    "Makan banyak sayuran": ["makan sayuran", "banyak sayuran", "sayuran"],
    "Hindari makanan berlemak": ["hindari lemak", "kurangi lemak", "batasi makanan berlemak"],
    "Jalan cepat": ["jalan cepat", "jalan kaki", "berjalan"],
    "Bersepeda": ["bersepeda", "sepeda", "mengendarai sepeda"],
    "Yoga": ["yoga", "latihan yoga"],
    "Senam ringan": ["senam ringan", "senam", "olahraga ringan"],
    "Buah": ["buah", "makan buah", "buah-buahan"],
    "Sayur": ["sayur", "makan sayur", "sayuran"],
    "Ikan": ["ikan", "makan ikan", "seafood"],
    "Kacang-kacangan": ["kacang", "kacang-kacangan", "makan kacang"],
    "Minum alkohol": ["minum alkohol", "alkohol", "konsumsi alkohol"],
    "Merokok": ["merokok", "rokok", "asap rokok"],
    "Obesitas": ["obesitas", "berat badan berlebih", "kegemukan"],
    "Tidak olahraga": ["tidak olahraga", "kurang olahraga", "tidak aktivitas fisik"],
    "Pola makan tidak teratur": ["pola makan tidak teratur", "makan tidak teratur", "tidak teratur makan"],
    "Konsumsi garam berlebihan": ["konsumsi garam berlebihan", "makan garam banyak", "garam berlebih"],
    "Makan tinggi garam": ["makan tinggi garam", "makanan asin", "makanan bergaram"],
    "Stress": ["stress", "stres", "tekanan mental"],
    "Kurang olahraga": ["kurang olahraga", "kurang aktivitas", "tidak olahraga"],
    "Sakit kepala": ["sakit kepala", "pusing", "kepala sakit"],
    "Penglihatan kabur": ["penglihatan kabur", "rabun", "mata kabur"],
    "Sesak nafas": ["sesak nafas", "nafas pendek", "sulit bernafas"],
    "Makanan yang mengandung garam": ["makanan bergaram", "makanan asin", "makanan tinggi garam"],
    "Makanan olahan": ["makanan olahan", "makanan kemasan", "produk olahan"],
    "Daging merah": ["daging merah", "daging sapi", "makan daging"],
    "Gorengan": ["gorengan", "makanan goreng", "digoreng"],
    "Mengurangi asupan garam": ["kurangi garam", "batasi garam", "makan sedikit garam"],
    "Rutin olahraga": ["olahraga rutin", "aktif olahraga", "aktivitas fisik"],
    "Tidak merokok": ["tidak merokok", "hindari rokok", "tidak rokok"],
    "Makan buah dan sayur": ["makan buah", "makan sayur", "buah dan sayur"],
    "Serangan jantung": ["serangan jantung", "sakit jantung", "penyakit jantung"],
    "Gagal ginjal": ["gagal ginjal", "penyakit ginjal", "gangguan ginjal"],
    "Lansia": ["lansia", "orang tua", "usia lanjut"],
    "Riwayat keluarga": ["riwayat keluarga", "keturunan", "genetik"],
    "Penyakit jantung": ["penyakit jantung", "gangguan jantung", "sakit jantung"],
    "Gangguan penglihatan": ["gangguan penglihatan", "penglihatan kabur", "masalah mata"],
    "Gangguan syaraf": ["gangguan syaraf", "masalah syaraf", "syaraf terganggu"],
    "Kematian dini": ["kematian dini", "meninggal muda", "resiko kematian"],
    "Rutin kontrol": ["kontrol rutin", "cek teratur", "konsultasi dokter"],
    "Diet": ["diet", "pola makan sehat", "menjaga makan"],
    "Kelola stress": ["kelola stres", "hindari stres", "manajemen stres"],
    "Pusing hebat": ["pusing hebat", "pusing parah", "sakit kepala parah"],
    "Sakit dada": ["sakit dada", "nyeri dada", "dada sakit"],
    "Lelah": ["lelah", "capek", "kelelahan"],
    "Konsumsi garam berlebih": ["garam berlebih", "makan banyak garam", "garam tinggi"],
    "Kurang aktivitas fisik": ["kurang aktivitas", "kurang gerak", "gaya hidup pasif"]
  };

export default synonymDict;