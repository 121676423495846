import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Logo from "../assets/logo_transparent.png";
import backgroundUrl from "../assets/background_with_object.png";
import Platform from "./platform";

const SplashScreen = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState(1);

  const handleNextClick = () => {
    if (section < 3) {
      setSection(section + 1);
    } else {
      navigate("/login");
    }
  };

  const handlePrevClick = () => {
    if (section > 1) {
      setSection(section - 1);
    }
  };

  // Konten untuk setiap bagian
  const sectionContent = [
    {
      title: "MataHati",
      description:
        "Selamat datang di aplikasi game Edukasi MATAHATI (Mari Taat Halau hiperTensI)",
    },
    {
      title: "Safari Hipertensi",
      description:
        "Aplikasi ini menyediakan menu menarik yang dikemas dalam Safari Hipertensi yang berisi materi hipertensi, cara sehat bersama hipertensi, Mitos/Fakta tentang hipertensi, dan permainan game yang menarik seputar hipertensi. Ajaklah keluargamu untuk bersama-sama menyimak dan memainkan aplikasi edukasi hipertensi ini.",
    },
    {
      title: "Anjuran Penggunaan Aplikasi",
      description:
        "Bukalah setiap menu. Bacalah dan simaklah penjelasannya. Cobalah permainan game secara bertahap dengan senang hati.",
    },
  ];

  return (
    <Platform>
      <div
        className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-4"
        style={{
          backgroundImage: `url(${backgroundUrl})`,
        }}
      >
        <div className="bg-white rounded-3xl shadow-lg w-80 p-6 flex flex-col items-center">
          <img src={Logo} alt="Character Logo" className="w-24 h-auto mb-4" />

          <h1 className="text-center text-xl font-bold text-pink-500 mb-2">
            {sectionContent[section - 1].title}
          </h1>

          <p className="text-center text-gray-500 mb-6">
            {sectionContent[section - 1].description}
          </p>

          <div className="flex gap-4 mb-4">
            {section > 1 && (
              <button
                onClick={handlePrevClick}
                className="w-12 h-12 bg-gray-300 rounded-full shadow-inner shadow-neutral-700 flex items-center justify-center text-gray-700 hover:bg-gray-400"
              >
                <FaArrowLeft />
              </button>
            )}
            <button
              onClick={handleNextClick}
              className="w-12 h-12 bg-matahati-secondary-200 rounded-full shadow-inner shadow-neutral-700 flex items-center justify-center text-white hover:bg-matahati-secondary-300"
            >
              <FaArrowRight />
            </button>
          </div>
        </div>
        {/* Copyright Text */}
        <div className="absolute w-64 bottom-8 text-center">
          <p className="text-xs text-white">
            By STIKes Panti Rapih Yogyakarta
          </p>
        </div>
      </div>
    </Platform>
  );
};

export default SplashScreen;
