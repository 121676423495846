// Splash.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Platform from "./platform";

function Splash() {
  const navigate = useNavigate();

  useEffect(() => {
    // Set timer for redirect
    const timer = setTimeout(() => {
      navigate("/intro");
    }, 2000);

    // Clear timer on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Platform>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f28b8b",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={require("../assets/splash.png")}
          alt="Splash Screen"
          style={{ width: "100%" }}
        />
      </div>
    </Platform>
  );
}

export default Splash;
