import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab1Stage2 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab1Stage2 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:1, stage:2},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[1][2] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/1/3');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Hipertensi</h2>
          <a href='/edukasi/2/1' className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </a>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Bagaimana Menilai Tekanan Darah?</h3>
          {/* buat tabel di sini */}
            <table className="w-full text-left mt-4 table-auto">
                <thead>
                <tr>
                    <th className="bg-matahati-primary-400 text-white px-4 py-2">Kategori</th>
                    <th className="bg-matahati-primary-400 text-white px-4 py-2">Tekanan Darah Sistolik</th>
                    <th className="bg-matahati-primary-400 text-white px-4 py-2">Tekanan Darah Diastolik</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="px-4 py-2">Normal</td>
                    <td className="px-4 py-2">&lt;120 mmHg</td>
                    <td className="px-4 py-2">&lt;80 mmHg</td>
                </tr>
                <tr>
                    <td className="px-4 py-2">Prehipertensi</td>
                    <td className="px-4 py-2">120 - 139 mmHg</td>
                    <td className="px-4 py-2">80 - 89 mmHg</td>
                </tr>
                <tr>
                    <td className="px-4 py-2">Hipertensi Derajat I</td>
                    <td className="px-4 py-2">140 - 159 mmHg</td>
                    <td className="px-4 py-2">90 - 99 mmHg</td>
                </tr>
                <tr>
                    <td className="px-4 py-2">Hipertensi Derajat II</td>
                    <td className="px-4 py-2">&ge;160 mmHg</td>
                    <td className="px-4 py-2">&ge;100 mmHg</td>
                </tr>
                </tbody>
            </table>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/1/1')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab1Stage2}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab1Stage2;
