const questionTrueFalse = {
  "sets": [
    {
      "setNumber": 1,
      "questions": [
        {
          "question": "Seseorang yang mengalami hipertensi suka marah-marah.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Makan sayur 'nget-ngetan' tidak baik untuk penderita hipertensi karena mengandung garam yang lebih tinggi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Orang tua menderita hipertensi itu hal yang normal dan wajar.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Orang yang mengalami hipertensi tetapi tidak pernah ada keluhan artinya tidak berbahaya.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi sama sekali tidak boleh makan garam.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi tidak boleh makan daging kambing.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi harus minum obat secara terus menerus.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Berjalan-jalan pagi 30 menit setiap hari merupakan olahraga untuk hipertensi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Hipertensi yang diturunkan oleh keluarga tidak dapat dicegah.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Tanda pasti hipertensi yang utama adalah sakit kepala.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        }
      ]
    },
    {
      "setNumber": 2,
      "questions": [
        {
          "question": "Peningkatan tekanan darah diastolik atau sistolik merupakan indikasi dari tekanan darah tinggi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Hipertensi dapat menyebabkan stroke, penyakit jantung, gagal ginjal, jika tidak ditangani.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Hipertensi dapat menimbulkan kebutaan pada mata.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi kontrol dokter hanya pada saat merasa badan tidak enak.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Jika obat tekanan darah tinggi dapat mengontrol tekanan darah, maka tidak perlu mengubah gaya hidup.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Individu dengan peningkatan tekanan darah harus minum obat mereka hanya ketika mereka merasa sakit.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Ikut serta dalam aktivitas fisik secara teratur (misalnya, berjalan kaki selama 30 menit 4–5.5 kali per minggu) adalah hal baik bagi penderita hipertensi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Batasan garam yang boleh dimakan oleh penderita hipertensi adalah kurang dari 1 sendok teh per hari (6 gram).",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi makan lebih sedikit makanan yang mengandung banyak lemak (misalnya daging merah, mentega, margarine, otak, paru, dll).",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi harus membaca label pada bungkus makanan yang akan dimakan untuk memeriksa keterangan kandungan lemak dan garamnya.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        }
      ]
    },
    {
      "setNumber": 3,
      "questions": [
        {
          "question": "Penderita hipertensi boleh makan asin dan berlemak asal segera minum obat hipertensi.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Makan lebih banyak buah dan sayuran setiap hari adalah kebiasaan baik untuk penderita hipertensi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi boleh memakan ayam goreng jika telah meminum obat hipertensi.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi perlu membatasi penggunaan bumbu yang mengandung banyak garam (mis., Saus tomat, kecap, terasi, bumbu penyedap).",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Untuk individu dengan peningkatan tekanan darah, metode memasak terbaik adalah menggoreng.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Menurunkan berat badan merupakan cara untuk mencegah tekanan darah semakin tinggi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Mengendalikan situasi yang menyebabkan tingkat stres adalah salah satu cara untuk mencegah tekanan darah semakin tinggi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi dilarang menjadi perokok aktif namun boleh perokok pasif.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Kebiasaan minum-minuman yang beralkohol dan makan yang mengandung alkohol harus dihindari pada penderita hipertensi.",
          "answer": "Fakta",
          "correct": -3,
          "false": 5.5
        },
        {
          "question": "Penderita hipertensi boleh makan durian jika minum obat hipertensi secara teratur.",
          "answer": "Mitos",
          "correct": -3,
          "false": 5.5
        }
      ]
    }
  ]
}

export default questionTrueFalse;