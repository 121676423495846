import React from "react";
import { Children } from 'react';

const Platform = ({ children }) => {
  return (
    <div className="w-screen h-screen bg-gradient-to-br from-slate-600 to-slate-900">
      <div className="bg-matahati-primary-300 w-full h-full sm:w-96 mx-auto overflow-y-auto no-scrollbar">
        {Children.map(children, child =>
          <div className="w-full h-full">
            {child}
          </div>
        )}
      </div>
    </div>
  );
}

export default Platform;