import React, { useState, useEffect, useRef } from "react";
import Platform from "./platform";
import { useNavigate } from "react-router-dom";
import { api, getAuthHeader } from "../controllers/getUserInfo";
import BackButton from "../components/BackButton";
import ResultComponent from "../components/Result";
import fetchPackage from "../controllers/getLevelToPlay";
import {PopupTutorial, PopupReaction, ChooseLevel} from "../components/Popup";
import wordGuessQuestions from "../data/wordGuessQuestions";

const WordGuessGame = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState([]);
  const [hintIndices, setHintIndices] = useState([]);
  const [isCorrect, setIsCorrect] = useState(null);
  const [score, setScore] = useState(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [allHintIndices, setAllHintIndices] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const [showIncorrectBackground, setShowIncorrectBackground] = useState(false);
  const [questionPackage, setQuestionPackage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopupReaction, setShowPopupReaction] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [popupLevel, setPopupLevel] = useState(false);

  const inputRefs = useRef([]);

  const getUserInfo = async () => {
    try {
      const response = await api.get("/api/user", {
        headers: getAuthHeader(),
      });
      console.log("User Info:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Failed to fetch user info:",
        error.response ? error.response.status : error.message
      );
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userData = await getUserInfo();
      setUserInfo(userData);
      const set = await fetchPackage("tebak_kata", "/game/guess-the-word");
      if (set) {
        setQuestionPackage(set);
        setIsLoading(false);
        setQuestions(dataQuestion.sets[set - 1].questions);
      } else {
        console.log("pelerrrr")
        setIsLoading(false)
        setPopupLevel(true);
        setShowPopup(false);
      }
    };
    fetchUserInfo();
    setScore(0);
    const dataQuestion = wordGuessQuestions;
    setCurrentQuestionIndex(0);
  }, []);

  const storeNewScore = async () => {
    try {
      const userId = userInfo.id;
      const score = correctAnswers * 20; // Hitung skor berdasarkan jawaban benar

      // Kirim data skor ke server dengan endpoint khusus untuk guessPict
      const response = await api.post(
        "/api/post-tebak-kata",
        {
          user_id: userId,
          set: questionPackage,
          score: score,
          completed: score >= 80,
        },
        {
          headers: getAuthHeader(),
        }
      );
      console.log("userId" + userId);
      console.log("score" + score);

      // Ambil data pengguna yang tersimpan di localStorage
      const previousData = JSON.parse(localStorage.getItem("user_data"));
      if (!previousData) {
        navigate("/login"); // Jika data tidak ditemukan, arahkan ke halaman login
        return;
      }

      // Update skor dan status kelulusan untuk game `guessPict`
      previousData.tebak_kata[questionPackage].pass = score >= 80; // Kelulusan berdasarkan threshold 80
      previousData.tebak_kata[questionPackage].score = score; // Simpan skor terbaru

      // Simpan perubahan ke localStorage
      localStorage.setItem("user_data", JSON.stringify(previousData));

      console.log("User Info:", response.data);
    } catch (error) {
      console.error(
        "Failed to store score:",
        error.response ? error.response.status : error.message
      );
      if (error.response && error.response.status === 401) {
        navigate("/login"); // Jika ada kesalahan autentikasi, arahkan ke login
      }
    }
  };

  useEffect(() => {
    if (wordGuessQuestions.sets[questionPackage]) {
      setQuestions(wordGuessQuestions.sets[questionPackage].questions);
    } else {
      console.error(
        "Package not found. Please check questionPackage or questions data."
      );
    }
  }, [questionPackage]);

  useEffect(() => {
    if (isGameOver && userInfo) {
      storeNewScore();
    }
  }, [isGameOver, userInfo]);

  useEffect(() => {
    if (wordGuessQuestions.sets[questionPackage]) {
      setQuestions(wordGuessQuestions.sets[questionPackage].questions);
    } else {
      console.error(
        "Package not found. Please check questionPackage or questions data."
      );
    }
  }, [questionPackage]);

  useEffect(() => {
    if (isGameOver && userInfo) {
      storeNewScore();
    }
  }, [isGameOver, userInfo]);
  const generateHintIndices = (answerLength) => {
    const hintCount = Math.floor(answerLength * 0.5);
    const indices = new Set();
    while (indices.size < hintCount) {
      indices.add(Math.floor(Math.random() * answerLength));
    }
    return [...indices];
  };

  useEffect(() => {
    if (questions.length > 0) {
      const newHintIndices = questions.map((question) =>
        generateHintIndices(question.answer.length)
      );
      setAllHintIndices(newHintIndices);
      setAnswer(Array(questions[0].answer.length).fill(""));
      setIsCorrect(null);
    }
  }, [questions]);

  useEffect(() => {
    if (questions.length > 0 && allHintIndices.length > currentQuestionIndex) {
      const currentAnswer = questions[currentQuestionIndex].answer;
      setAnswer(Array(currentAnswer.length).fill(""));
      setIsCorrect(null);
      setHintIndices(allHintIndices[currentQuestionIndex] || []);
    }
  }, [currentQuestionIndex, questions, allHintIndices]);

  const handleLetterChange = (e, index) => {
    if (
      hintIndices.includes(index) ||
      answeredQuestions[currentQuestionIndex] === "correct"
    )
      return;

    const updatedAnswer = [...answer];
    updatedAnswer[index] = e.target.value.toUpperCase();
    setAnswer(updatedAnswer);

    if (e.target.value) {
      let nextIndex = index + 1;
      while (nextIndex < answer.length && hintIndices.includes(nextIndex)) {
        nextIndex++;
      }

      if (
        nextIndex < inputRefs.current.length &&
        inputRefs.current[nextIndex]
      ) {
        inputRefs.current[nextIndex].focus();
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !answer[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (questions.length > 0) {
      const currentAnswer =
        questions[currentQuestionIndex].answer.toUpperCase();
      if (answeredQuestions[currentQuestionIndex] === "correct") {
        setAnswer(currentAnswer.split(""));
      } else {
        setAnswer(Array(currentAnswer.length).fill(""));
        generateHintIndices(currentAnswer.length);
      }
      setIsCorrect(null);
    }
  }, [currentQuestionIndex, questions]);

  const handleSubmit = () => {
    if (answeredQuestions[currentQuestionIndex] === "correct") {
      return;
    }
  
    const fullAnswer = answer
      .map((letter, index) =>
        hintIndices.includes(index)
          ? currentQuestion.answer[index].toUpperCase()
          : letter
      )
      .join("")
      .toUpperCase();
  
    const correctAnswer = questions[currentQuestionIndex].answer.toUpperCase();
    
    if (fullAnswer === correctAnswer) {
      setIsCorrect(true);
      setScore((prevScore) => prevScore + 20);
      setAnsweredQuestions((prev) => ({
        ...prev,
        [currentQuestionIndex]: "correct",
      }));
      setAnswer(correctAnswer.split(""));
      setShowPopupReaction(true); 
      setTimeout(() => setShowPopupReaction(false), 2000); // Hide popup after 2 seconds
  
      setIsLoadingNext(true);
      setTimeout(() => {
        setIsLoadingNext(false);
      }, 1000);
    } else {
      setIsCorrect(false);
      setAnsweredQuestions((prev) => ({
        ...prev,
        [currentQuestionIndex]: "incorrect",
      }));
      setShowPopupReaction(true); 
      setShowIncorrectBackground(true);
  
      const resetAnswer = answer.map((_, index) =>
        hintIndices.includes(index) ? currentQuestion.answer[index].toUpperCase() : ""
      );
      setAnswer(resetAnswer);
  
      setTimeout(() => {
        setShowPopupReaction(false); 
        setShowIncorrectBackground(false);
      }, 2000);
    }
  };

  const handleCloseLevel = () => {
    setPopupLevel(false);
  }

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsCorrect(null);
    } else {
      setIsGameOver(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setIsCorrect(null);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handlePlay = () => {
    setShowPopup(false);
    handlePlayGame();
  };

  const handlePlayGame = () => {
    setScore(0);
    setCurrentQuestionIndex(0);
    setAnsweredQuestions({});
    setIsGameOver(false);
  };

  const handleExitGame = () => {
    alert("Thank you for playing!");
  };
  if (isLoading) {
    return (
      <Platform>
        <div className="w-full h-full flex flex-col items-center">
          <div className="bg-matahati-primary-300 min-h-screen w-full py-6 flex justify-center items-center text-white font-semibold">
            Loading Game....
          </div>
        </div>
      </Platform>
    );
  }
  if (questions.length === 0) return <p>Loading...</p>;

  const currentQuestion = questions[currentQuestionIndex];
  const currentAnswer = currentQuestion.answer;
  const totalQuestions = questions.length;
  const correctAnswers = score / 20;
  const incorrectAnswers = totalQuestions - correctAnswers;
  const accuracy = ((correctAnswers / totalQuestions) * 100).toFixed(0);
  return (
    <Platform>
      <div className="bg-matahati-primary-300 h-full  text-white">
        {showPopup && (
          <PopupTutorial
            title="Tebak Kata"
            description="Pemain harus menjawab pertanyaan dengan cara melengkapi kata rumpang yang telah disediakan."
            buttonText="Mainkan"
            handleClose={handleClosePopup}
            handleAction={handlePlay}
          />
        )}
        {popupLevel && (
          <ChooseLevel
            handleClose={handleCloseLevel}
            gameName={"tebak_kata"}
            questionsData={wordGuessQuestions}
            questionState={setQuestions}
          />
        )}
        {!showPopup && !isGameOver && (
          <>
            {showPopupReaction && <PopupReaction isCorrect={isCorrect} />}
            <div className="w-full h-full flex flex-col items-center  py-6 px-8">
              <BackButton
                title={"Tebak Kata"}
                handleExitGame={handleExitGame}
              />
              <div className="flex gap-2 mb-6">
                {questions.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentQuestionIndex(index)}
                    className={`w-8 h-8 rounded-lg  border-white text-white text-lg ${
                      answeredQuestions[index] === "correct"
                        ? "bg-green-500 border-green-50 text-green-50"
                        : answeredQuestions[index] === "incorrect"
                        ? "bg-red-500"
                        : "bg-matahati-primary-300 border border-1 "
                    } ${
                      index === currentQuestionIndex
                        ? "border-2 border-matahati-primary-500 text-matahati-primary-500"
                        : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <img
                src={require("../assets/question_mark.png")}
                alt="Question"
                className="w-64 h-auto mt-3"
              />
              <div className="bg-white text-matahati-primary-500 font-bold rounded-3xl py-5 px-4 text-center text-lg mb-8 w-full shadow-md min-h-[10rem] flex items-center justify-center">
                {currentQuestion.question}
              </div>

              <div className="flex flex-wrap gap-2 justify-center px-4 max-w-full min-h-[5.5rem] mb-3   overflow-x-auto">
                {answer.map((letter, index) => (
                  <input
                    key={index}
                    type="text"
                    autoComplete="off"
                    value={
                      hintIndices.includes(index) && currentAnswer[index]
                        ? currentAnswer[index].toUpperCase()
                        : letter
                    }
                    onChange={(e) => handleLetterChange(e, index)}
                    onKeyDown={(e) => {
                      handleBackspace(e, index);
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    maxLength="1"
                    ref={(el) => (inputRefs.current[index] = el)}
                    readOnly={
                      hintIndices.includes(index) ||
                      answeredQuestions[currentQuestionIndex] === "correct"
                    }
                    className={`text-lg text-center rounded-lg font-bold w-10 h-10 border-b-4 border-gray-800 min-w-0 ${
                      answeredQuestions[currentQuestionIndex] === "correct"
                        ? "bg-green-500"
                        : showIncorrectBackground
                        ? "bg-red-300 shake"
                        : hintIndices.includes(index)
                        ? "bg-matahati-primary-400"
                        : "bg-pink-50 text-gray-900"
                    }`}
                  />
                ))}
              </div>

              <button
                onClick={
                  isCorrect ||
                  answeredQuestions[currentQuestionIndex] === "correct"
                    ? currentQuestionIndex === questions.length - 1
                      ? () => setIsGameOver(true)
                      : handleNextQuestion
                    : handleSubmit
                }
                className={`bg-matahati-secondary-200 text-white font-semibold py-2 px-8 rounded-3xl ${
                  isLoadingNext ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoadingNext}
              >
                {isLoadingNext
                  ? "Loading..."
                  : isCorrect ||
                    answeredQuestions[currentQuestionIndex] === "correct"
                  ? currentQuestionIndex === questions.length - 1
                    ? "Selesai"
                    : "Selanjutnya"
                  : "Jawab"}
              </button>

              <div className="flex w-full mt-6 justify-between gap-4">
                <button
                  onClick={handlePreviousQuestion}
                  className="bg-matahati-primary-300 w-32 border border-white text-white font-semibold text-xs py-2 px-6 rounded-3xl"
                  disabled={currentQuestionIndex === 0}
                >
                  Sebelumnya
                </button>
                <button
                  onClick={handleNextQuestion}
                  className="bg-matahati-primary-400 w-32 text-white font-semibold text-xs px-6 rounded-3xl"
                >
                  {currentQuestionIndex === questions.length - 1
                    ? "Selesai"
                    : "Lewati"}
                </button>
              </div>
            </div>
          </>
        )}
        {isGameOver && (
          <ResultComponent
            origin={"/game/guess-the-word"}
            score={accuracy}
            correct={correctAnswers}
            incorrect={incorrectAnswers}
          />
        )}
      </div>
    </Platform>
  );
};

export default WordGuessGame;
