const handleTfAnswer = (
  answer, 
  totalHP, 
  questionLength, 
  setRemainHP, 
  setCurrentNumber,
  setCorrectAnswer, 
  setIsDone,
  setAnswerStatus,
  setIsCorrect,
  setIsInCorrect
) => () => {
  let poin;
  setRemainHP((prevHP) => {
    !answer ? poin = 5.5 : poin = -3;

    const updatedHP = Math.min(Math.max(prevHP + poin, 90), 180);

    if (poin < 0) {
      setCorrectAnswer((prev) => prev + 1);
    }

    return updatedHP;
  });

  if (answer) {
    setIsCorrect(true);
    setTimeout(() => {
      setIsCorrect(false);
    }, 1600) //atur jeda nutupnya
  } else if (!answer) {
    setIsInCorrect(true);
    setTimeout(() => {
      setIsInCorrect(false);
    }, 1600) //atur jeda nutupnya
  }

  setCurrentNumber((prevNum) => {
    setAnswerStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      poin < 0 ? newStatus[prevNum] = true : newStatus[prevNum] = false; 
      return newStatus;
    });

    if (prevNum + 1 >= questionLength) {
      setTimeout(1500);
      setIsDone(true);
      return prevNum;
    } else {
      return prevNum + 1;
    }
  });
};


export default handleTfAnswer;
