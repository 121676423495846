import React, { useEffect, useState } from "react";
import axios from "axios";
import Platform from "./platform";
import handleTextInput from "../controllers/handleInputs";
import { useNavigate } from "react-router-dom";
import { api } from "../controllers/getUserInfo";

const Login = () => {
  const [username, setUsername] = useState('');
  const [loginError, setLoginError] = useState(' ');
  const [loginState, setLoginState] = useState('Login');
  const navigate = useNavigate();

  const getCsrfToken = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`, {
      withCredentials: true
    });
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    try {
      setLoginError(' ');
      setLoginState('Tunggu sebentar...');
      await getCsrfToken();
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/login`,
        { username },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response);
      setLoginState('Login');
      localStorage.setItem('user_data', JSON.stringify(response.data));
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('token_type', response.data.token_type);

      navigate('/dashboard');
      
    } catch (error) {
      setLoginState('Login')
      if (error.response.status === 422) {
        setLoginError('Username salah atau tidak ditemukan di database');
      }
      console.error('Login failed:', error.response.status);
    }
  };

  useEffect(() => {
    setLoginError('');
  }, []);

  return (
    <Platform>
      <div className="h-full bg-white flex flex-col items-center justify-center px-8">
        <div className="flex flex-col w-full h-full items-center justify-center gap-8">
          <h1 className="text-matahati-primary-400 font-bold  text-5xl">
            Login
          </h1>
          <form
            className="w-full flex flex-col gap-4 items-center"
            onSubmit={handleSubmitLogin}
          >
            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                placeholder="username"
                className="border border-neutral-500 rounded-lg ps-2 py-2 font-light"
                value={username}
                onChange={handleTextInput(setUsername)}
              />
              <p className="text-matahati-primary-400 text-sm">{loginError}</p>
            </div>
            <button
              type="submit"
              className="bg-matahati-primary-300 font-bold text-white text-lg items-center rounded-full px-16 py-2 shadow-inner shadow-gray-800"
            >
              {loginState}
            </button>
            <p className="text-center font-thin">
              Belum punya akun?{" "}
              <a
                href="/register"
                className="font-bold text-matahati-primary-400 underline"
              >
                Klik di Sini!
              </a>
            </p>
          </form>
        </div>
      </div>
    </Platform>
  );
}

export default Login;