import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import Login from "./views/login";
import PictGuess from "./views/pictGuess";
import WordGuessGame from "./views/wordGuess";
import Family100 from "./views/family100";
import Survey100 from "./views/survey100";
import Dashboard from "./views/dashboard";
import TrueFalseGame from "./views/TrueFalseGame";
import StageSelection from "./views/island/StageSelection";
import CrosswordGame from "./views/crossword";
import Intro from "./views/intro";
import Splash from "./views/splash";
import Profile from "./views/profile";
import EditProfile from "./views/editProfile";
import Bab1Stage1 from "./views/edukasi/bab-1/Bab1Stage1";
import Bab1Stage2 from "./views/edukasi/bab-1/Bab1Stage2";
import Bab1Stage3 from "./views/edukasi/bab-1/Bab1Stage3";
import Bab2Stage1 from "./views/edukasi/bab-2/Bab2Stage1";
import Bab2Stage2 from "./views/edukasi/bab-2/Bab2Stage2";
import Bab3Stage1 from "./views/edukasi/bab-3/Bab3Stage1";
import Register from "./views/Register";
import Bab4Stage1 from "./views/edukasi/bab-4/Bab4Stage1";
import Bab5Stage1 from "./views/edukasi/bab-5/Bab5Stage1";
import Bab6Stage1 from "./views/edukasi/bab-6/Bab6Stage1";
import Bab6Stage2 from "./views/edukasi/bab-6/Bab6Stage2";
import Bab6Stage3 from "./views/edukasi/bab-6/Bab6Stage3";
import Bab6Stage5 from "./views/edukasi/bab-6/Bab6Stage5";
import Bab6Stage4 from "./views/edukasi/bab-6/Bab6Stage4";
import Bab6Stage6 from "./views/edukasi/bab-6/Bab6Stage6";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/splash" replace />} />
        <Route path="/splash" element={<Splash />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/island" element={<StageSelection />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/edukasi/1/1" element={<Bab1Stage1 />} />
        <Route path="/edukasi/1/2" element={<Bab1Stage2 />} />
        <Route path="/edukasi/1/3" element={<Bab1Stage3 />} />
        <Route path="/edukasi/2/1" element={<Bab2Stage1 />} />
        <Route path="/edukasi/2/2" element={<Bab2Stage2 />} />
        <Route path="/edukasi/3/1" element={<Bab3Stage1 />} />
        <Route path="/edukasi/4/1" element={<Bab4Stage1 />} />
        <Route path="/edukasi/5/1" element={<Bab5Stage1 />} />
        <Route path="/edukasi/6/1" element={<Bab6Stage1 />} />
        <Route path="/edukasi/6/2" element={<Bab6Stage2 />} />
        <Route path="/edukasi/6/3" element={<Bab6Stage3 />} />
        <Route path="/edukasi/6/4" element={<Bab6Stage4 />} />
        <Route path="/edukasi/6/5" element={<Bab6Stage5 />} />
        <Route path="/edukasi/6/6" element={<Bab6Stage6 />} />
        <Route path="/game/guess-the-picture" element={<PictGuess />} />
        <Route path="/game/guess-the-word" element={<WordGuessGame />} />
        <Route path="/game/family100" element={<Family100 />} />
        <Route path="/game/survey100" element={<Survey100 />} />
        <Route path="/game/true-false" element={<TrueFalseGame />} />
        <Route path="/game/crossword" element={<CrosswordGame />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
