const crosswordQuestions = {
  "set": [
    {
      "set": "1",
      "questions": {
        "across": {
          1: {
            "clue": "Nilai atas pada hasil pengukuran tekanan darah",
            "answer": "SISTOLIK",
            "options": ["Sistolik", "Diastole"],
            "row": 2,
            "col": 2
          },
          2: {
            "clue": "Nilai tekanan darah sistolik lebih dari 140 mmHg",
            "answer": "HIPERTENSI",
            "options": ["Hipertensi", "Hipertermi"],
            "row": 4,
            "col": 0
          },
          3: {
            "clue": "Faktor resiko hipertensi yang menetap",
            "answer": "USIA",
            "options": ["Usia", "Diet"],
            "row": 8,
            "col": 6
          },
        },
        "down": {
          1: {
            "clue": "Hipertensi yang tidak diketahui penyebabnya",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 0,
            "col": 3
          },
          2: {
            "clue": "Nilai bawah pada hasil pengukuran tekanan darah",
            "answer": "DIASTOLIK",
            "options": ["Diastolik", "Siastolik"],
            "row": 1,
            "col": 8
          },
          3: {
            "clue": "Bumbu penyebab hipertensi",
            "answer": "SAUS",
            "options": ["Saus", "Lada"],
            "row": 6,
            "col": 6
          },
        }
      }
    },
    {
      "set": "2",
      "questions": {
        "across": {
          1: {
            "clue": "Kebiasaan buruk yang meningkatkan risiko hipertensi",
            "answer": "MEROKOK",
            "options": ["Merokok", "Cigaret"],
            "row": 1,
            "col": 0
          },
          2: {
            "clue": "Salah satu organ yang bisa mengalami kerusakan karena hipertensi",
            "answer": "GINJAL",
            "options": ["Ginjal", "Cardio"],
            "row": 3,
            "col": 2
          },
          3: {
            "clue": "Hipertensi yang penyebabnya tidak diketahui dengan jelas",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 7,
            "col": 5
          },
        },
        "down": {
          1: {
            "clue": "Yang harus dikurangi oleh pasien hipertensi",
            "answer": "SODIUM",
            "options": ["Sodium", "Kalium"],
            "row": 0,
            "col": 3
          },
          2: {
            "clue": "Pembuluh darah yang mengalirkan darah dari jantung ke seluruh tubuh",
            "answer": "ARTERI",
            "options": ["Arteri", "Venole"],
            "row": 3,
            "col": 6
          },
          3: {
            "clue": "Diturunkan dari orang tua",
            "answer": "GENETIK",
            "options": ["Genetik", "Turunan"],
            "row": 6,
            "col": 9
          },
        }
      }
    },
    {
      "set": "3",
      "questions": {
        "across": {
          1: {
            "clue": "Keadaan peningkatan pada tekanan darah sistolik >140 mmHg atau tekanan darah diastolik >90 mmHg",
            "answer": "HIPERTENSI",
            "options": ["Hipertensi", "Hipertermi"],
            "row": 0,
            "col": 1
          },
          2: {
            "clue": "Untuk mengukur tekanan darah",
            "answer": "TENSIMETER",
            "options": ["Tensimeter", "Termometer"],
            "row": 2,
            "col": 0
          },
          3: {
            "clue": "Faktor risiko hipertensi yang tidak dapat diubah",
            "answer": "GENETIK",
            "options": ["Genetik", "Turunan"],
            "row": 5,
            "col": 3
          },
        },
        "down": {
          1: {
            "clue": "Salah satu gejala hipertensi",
            "answer": "PUSING",
            "options": ["Pusing", "Muntah"],
            "row": 0,
            "col": 3
          },
          2: {
            "clue": "Makanan yang mempunyai lemak jenuh tinggi",
            "answer": "OTAK",
            "options": ["Otak", "Susu"],
            "row": 4,
            "col": 7
          },
          3: {
            "clue": "Dapat meningkatkan risiko hipertensi",
            "answer": "KOLESTEROL",
            "options": ["Kolesterol", "Hipertermi"],
            "row": 5,
            "col": 9
          },
        }
      }
    },
    {
      "set": "4",
      "questions": {
        "across": {
          1: {
            "clue": "Penyebab hipertensi primer berupa gaya hidup",
            "answer": "MEROKOK",
            "options": ["Merokok", "Genetik"],
            "row": 0,
            "col": 0 
          },
          2: {
            "clue": "Makanan mengandung alkohol",
            "answer": "DURIAN",
            "options": ["Durian", "Pepaya"],
            "row": 4,
            "col": 3
          },
          3: {
            "clue": "Pecah pembuluh darah di otak",
            "answer": "STROKE",
            "options": ["Stroke", "Cidera"],
            "row": 6,
            "col": 7
          },
        },
        "down": {
          1: {
            "clue": "Cara mencegah hipertensi",
            "answer": "OLAHRAGA",
            "options": ["Olahraga", "Kesenian"],
            "row": 0,
            "col": 5
          },
          2: {
            "clue": "Bumbu penyebab hipertensi",
            "answer": "SAUS",
            "options": ["Saus", "Lada"],
            "row": 3,
            "col": 7
          },
          3: {
            "clue": "Makanan banyak mengandung garam natrium",
            "answer": "BISKUIT",
            "options": ["Biskuit", "Alpukat"],
            "row": 3,
            "col": 11
          },
        }
      }
    },
    {
      "set": "5",
      "questions": {
        "across": {
          1: {
            "clue": "Unsur berbahaya di dalam rokok",
            "answer": "NIKOTIN",
            "options": ["Nikotin", "Natrium"],
            "row": 2,
            "col": 0 
          },
          2: {
            "clue": "Untuk mengukur tekanan darah",
            "answer": "TENSIMETER",
            "options": ["Tensimeter", "Termometer"],
            "row": 4,
            "col": 4 
          },
          3: {
            "clue": "Makanan mengandung alkohol",
            "answer": "DURIAN",
            "options": ["Durian", "Pepaya"],
            "row": 9,
            "col": 0 
          },
        },
        "down": {
          1: {
            "clue": "Hipertensi yang dapat diturunkan di keluarga",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 0,
            "col": 5 
          },
          2: {
            "clue": "Dapat meningkatkan risiko hipertensi",
            "answer": "KOLESTEROL",
            "options": ["Kolesterol", "Hipertermi"],
            "row": 2,
            "col": 2 
          },
          3: {
            "clue": "Faktor resiko hipertensi akibat kelebihan berat badan",
            "answer": "GEMUK",
            "options": ["Gemuk", "Kekar"],
            "row": 3,
            "col": 10 
          },
        }
      }
    },
    {
      "set": "6",
      "questions": {
        "across": {
          1: {
            "clue": "Ditimbulkan karena banyak makan berlemak",
            "answer": "KOLESTEROL",
            "options": ["Kolesterol", "Gula Darah"],
            "row": 2,
            "col": 0
          },
          2: {
            "clue": "Menyebabkan penumpukan cairan tubuh",
            "answer": "GARAM",
            "options": ["Garam", "Lemak"],
            "row": 9,
            "col": 2
          },
          3: {
            "clue": "Penyebab hipertensi primer berupa gaya hidup",
            "answer": "MEROKOK",
            "options": ["Merokok", "Genetik"],
            "row": 11,
            "col": 4 
          },
        },
        "down": {
          1: {
            "clue": "Dapat meningkatkan risiko hipertensi",
            "answer": "KOLESTEROL",
            "options": ["Kolesterol", "Hipertermi"],
            "row": 0,
            "col":  9
          },
          2: {
            "clue": "Hipertensi timbul karena penyakit lain",
            "answer": "SEKUNDER",
            "options": ["Sekunder", "Genetika"],
            "row": 2,
            "col": 4
          },
          3: {
            "clue": "Hipertensi yang dapat diturunkan di keluarga",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 6,
            "col": 6
          },
        }
      }
    },
    {
      "set": "7",
      "questions": {
        "across": {
          1: {
            "clue": "Makanan banyak mengandung lemak jenuh",
            "answer": "KEJU",
            "options": ["Keju", "Ikan"],
            "row": 1,
            "col": 0
          },
          2: {
            "clue": "Tekanan darah yang lebih tinggi dari normal",
            "answer": "HIPERTENSI",
            "options": ["Hipertensi", "Hipertermi"],
            "row": 3,
            "col": 0
          },
          3: {
            "clue": "Makanan  kemasan yang mengandung banyak garam",
            "answer": "SOSIS",
            "options": ["Sosis", "Tempe"],
            "row": 7,
            "col": 7
          },
        },
        "down": {
          1: {
            "clue": "Gejala hipertensi",
            "answer": "GELISAH",
            "options": ["Gelisah", "Ngantuk"],
            "row": 0,
            "col": 1
          },
          2: {
            "clue": "Tekanan yang ditimbulkan saat jantung berdetak  untuk memompa atau mendorong darah ke seluruh tubuh",
            "answer": "SISTOLIK",
            "options": ["Sistolik", "Diastole"],
            "row": 3,
            "col": 8
          },
          3: {
            "clue": "Tanda gejala hipertensi",
            "answer": "PUSING",
            "options": ["Pusing", "Muntah"],
            "row": 5,
            "col": 11
          },
        }
      }
    },
    {
      "set": "8",
      "questions": {
        "across": {
          1: {
            "clue": "Kebiasaan buruk yang meningkatkan risiko hipertensi",
            "answer": "MEROKOK",
            "options": ["Merokok", "Cigaret"],
            "row": 1,
            "col": 0
          },
          2: {
            "clue": "Salah satu organ yang bisa mengalami kerusakan karena hipertensi",
            "answer": "GINJAL",
            "options": ["Ginjal", "Cardio"],
            "row": 3,
            "col": 2
          },
          3: {
            "clue": "Hipertensi yang penyebabnya tidak diketahui dengan jelas",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 7,
            "col": 5
          },
          4: {
            "clue": "Keadaan peningkatan pada tekanan darah sistolik >140 mmHg atau tekanan darah diastolik >90 mmHg",
            "answer": "HIPERTENSI",
            "options": ["Hipertensi", "Hipertermi"],
            "row": 10,
            "col": 4
          },
          5: {
            "clue": "Makanan banyak mengandung lemak jenuh",
            "answer": "KEJU",
            "options": ["Keju", "Ikan"],
            "row": 12,
            "col": 9
          },
        },
        "down": {
          1: {
            "clue": "Yang harus dikurangi oleh pasien hipertensi",
            "answer": "SODIUM",
            "options": ["Sodium", "Kalium"],
            "row": 0,
            "col": 3
          },
          2: {
            "clue": "Pembuluh darah yang mengalirkan darah dari jantung ke seluruh tubuh",
            "answer": "ARTERI",
            "options": ["Arteri", "Venole"],
            "row": 3,
            "col": 6
          },
          3: {
            "clue": "Diturunkan dari orang tua",
            "answer": "GENETIK",
            "options": ["Genetik", "Turunan"],
            "row": 6,
            "col": 9
          },
          4: {
            "clue": "Faktor resiko hipertensi akibat kelebihan berat badan",
            "answer": "GEMUK",
            "options": ["Gemuk", "Kekar"],
            "row": 9,
            "col": 7
          },
          5: {
            "clue": "Bumbu penyebab hipertensi",
            "answer": "SAUS",
            "options": ["Saus", "Lada"],
            "row": 10,
            "col": 12
          },
        }
      }
    },
    {
      "set": "9",
      "questions": {
        "across": {
          1: {
            "clue": "Keadaan peningkatan pada tekanan darah sistolik >140 mmHg atau tekanan darah diastolik >90 mmHg",
            "answer": "HIPERTENSI",
            "options": ["Hipertensi", "Hipertermi"],
            "row": 0,
            "col": 1
          },
          2: {
            "clue": "Untuk mengukur tekanan darah",
            "answer": "TENSIMETER",
            "options": ["Tensimeter", "Termometer"],
            "row": 2,
            "col": 0
          },
          3: {
            "clue": "Faktor risiko hipertensi yang tidak dapat diubah",
            "answer": "GENETIK",
            "options": ["Genetik", "Turunan"],
            "row": 5,
            "col": 3
          },
          4: {
            "clue": "Tekanan darah yang lebih tinggi dari normal",
            "answer": "HIPERTENSI",
            "options": ["Hipertensi", "Hipertermi"],
            "row": 9,
            "col": 1
          },
          5: {
            "clue": "Hipertensi yang penyebabnya tidak diketahui dengan jelas",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 12,
            "col": 8
          },
        },
        "down": {
          1: {
            "clue": "Salah satu gejala hipertensi",
            "answer": "PUSING",
            "options": ["Pusing", "Muntah"],
            "row": 0,
            "col": 3
          },
          2: {
            "clue": "Salah satu gejala hipertensi",
            "answer": "OTAK",
            "options": ["Otak", "Susu"],
            "row": 4,
            "col": 7
          },
          3: {
            "clue": "Dapat meningkatkan risiko hipertensi",
            "answer": "KOLESTEROL",
            "options": ["Kolesterol", "Hipertermi"],
            "row": 5,
            "col": 9
          },
          4: {
            "clue": "Makanan banyak mengandung garam natrium",
            "answer": "BISKUIT",
            "options": ["Biskuit", "Alpukat"],
            "row": 8,
            "col": 2
          },
          5: {
            "clue": "Gejala hipertensi",
            "answer": "GELISAH",
            "options": ["Gelisah", "Ngantuk"],
            "row": 11,
            "col": 12
          },
        }
      }
    },
    {
      "set": "10",
      "questions": {
        "across": {
          1: {
            "clue": "Hipertensi yang penyebabnya tidak diketahui dengan jelas",
            "answer": "PRIMER",
            "options": ["Primer", "Bawaan"],
            "row": 2,
            "col": 0
          },
          2: {
            "clue": "Penyebab hipertensi primer berupa gaya hidup",
            "answer": "MEROKOK",
            "options": ["Merokok", "Genetik"],
            "row": 4,
            "col": 1
          },
          3: {
            "clue": "Makanan mengandung alkohol",
            "answer": "DURIAN",
            "options": ["Durian", "Pepaya"],
            "row": 8,
            "col": 4
          },
          4: {
            "clue": "Pecah pembuluh darah di otak",
            "answer": "STROKE",
            "options": ["Stroke", "Cidera"],
            "row": 10,
            "col": 8
          },
          5: {
            "clue": "Faktor risiko hipertensi yang tidak dapat diubah",
            "answer": "GENETIK",
            "options": ["Genetik", "Turunan"],
            "row": 12,
            "col": 7
          },
        },
        "down": {
          1: {
            "clue": "Makanan yang bisa meningkatkan tekanan darah jika dikonsumsi berlebih",
            "answer": "GARAM",
            "options": ["Garam", "Sayur"],
            "row": 0,
            "col": 1
          },
          2: {
            "clue": "Cara mencegah hipertensi",
            "answer": "OLAHRAGA",
            "options": ["Olahraga", "Kesenian"],
            "row": 4,
            "col": 6
          },
          3: {
            "clue": "Bumbu penyebab hipertensi",
            "answer": "SAUS",
            "options": ["Saus", "Lada"],
            "row": 7,
            "col": 8
          },
          4: {
            "clue": "Pembuluh darah yang mengalirkan darah dari jantung ke seluruh tubuh",
            "answer": "ARTERI",
            "options": ["Arteri", "Venole"],
            "row": 9,
            "col": 10
          },
          5: {
            "clue": "Makanan banyak mengandung garam natrium",
            "answer": "BISKUIT",
            "options": ["Biskuit", "Alpukat"],
            "row": 7,
            "col": 12
          },
        }
      }
    }
  ]
}

export default crosswordQuestions;