import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Photo from '../assets/dokter.png';
import { BsArrowLeftCircleFill, BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab6Stage1 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab6Stage1 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:6, stage:1},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[6][1] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/6/2');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Cara Mengendalikan Hipertensi</h2>
          <button onClick={() => navigate('/island')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">1. Periksa Kesehatan Secara Rutin</h3>
          
          <div className="text-gray-700 text-lg">
          <p>Apakah penderita hipertensi harus kontrol ke dokter?</p>
          <img
            src={Photo}
            alt="Pressure Meter"
            className="w-full my-4 h-64 object-cover"
          />
          <ul className='mx-8' style={{listStyleType: 'circle'}}>
            <li>Penderita hipertensi wajib periksa kesehatan secara
            teratur dan rutin ke Puskesmas atau ke dokter.</li>
            <li>Yang harus di periksa rutin adalah tekanan darahnya. </li>
            <li>Adapun pemeriksaan lain yang haris dilaksanakan
secara berkala adalah pemeriksaan kadar lemah atau
bisa disebut kolesterol, rekam jantung dan periksa
urine atau air kencing. </li>
            <li>Penderita hipertensi harus kontrol ke dokter secara
teratur minimal setiap bulan untuk mengecek kondisi
kesehatannya dan mendapatkan pengobatan
hipertensinya, meskipun tidak ada tanda gejala atau
tidak sakit. </li>
          </ul>
          {/* Tambahkan peringatan 'Jangan Putus Kontrol!' */}
            <div className="bg-red-500 text-white p-4 rounded-lg mt-4">
                Jangan Putus Kontrol!
                </div>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
        <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/5/1')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab6Stage1}>
            {lanjut}
          </button>
          </div>
      </div>
    </Platform>
  );
};

export default Bab6Stage1;
