import React from "react";
import { Link } from "react-router-dom";
import Platform from "./platform";

const ProfilePage = () => {
  return (
    <Platform>
      <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
        {/* Header */}
        <div className="flex items-center justify-between w-full max-w-md py-4">
          <h1 className="text-3xl font-bold text-pink-700">MaTaHaTi</h1>
          <button className="text-pink-700 text-2xl font-bold">✕</button>
        </div>

        {/* Subtitle */}
        <div className="text-center my-4">
          <h2 className="text-xl font-bold text-pink-700">Akun MaTaHaTi</h2>
          <p className="text-sm text-gray-600">
            Mulai sekarang, game akan disimpan sehingga kamu bisa bermain di
            perangkat apapun
          </p>
        </div>

        {/* Profile Picture and Info */}
        <div className="mt-4 flex flex-col items-center">
          <img
            src="https://via.placeholder.com/150" // Replace with user's profile image URL
            alt="Profile"
            className="w-32 h-32 rounded-full object-cover"
          />
          <h3 className="text-xl font-bold text-gray-800 mt-2">Gustian</h3>
          <p className="text-gray-500">riskigustiantoro@gmail.com</p>

          {/* Edit Button */}
          <button className="mt-4 px-6 py-2 bg-pink-500 text-white rounded-full font-bold hover:bg-pink-600">
            Edit
          </button>
        </div>

        {/* Menu Options */}
        <div className="w-full max-w-md mt-6">
          <Link
            to="/change-password"
            className="flex items-center justify-between px-4 py-3 bg-white rounded-lg shadow mb-2 hover:bg-pink-50"
          >
            <span className="text-gray-800 font-medium">Ubah Kata Sandi</span>
            <span className="text-pink-500">›</span>
          </Link>
          <Link
            to="/privacy-security"
            className="flex items-center justify-between px-4 py-3 bg-white rounded-lg shadow mb-2 hover:bg-pink-50"
          >
            <span className="text-gray-800 font-medium">
              Privasi dan Keamanan
            </span>
            <span className="text-pink-500">›</span>
          </Link>
          <Link
            to="/logout"
            className="flex items-center justify-between px-4 py-3 bg-white rounded-lg shadow mb-2 hover:bg-pink-50"
          >
            <span className="text-gray-800 font-medium">Keluar</span>
            <span className="text-pink-500">›</span>
          </Link>
        </div>
      </div>
    </Platform>
  );
};

export default ProfilePage;
