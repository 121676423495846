const questionData = {
  sets: [
    {
      setNumber: 1,
      questions: [
        {
          question: "Apakah faktor resiko hipertensi yang tidak dapat diubah?",
          options: [
            { option: "Usia", score: 35 },
            { option: "Jenis Kelamin", score: 25 },
            { option: "Genetik", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Genetik",
          sound: "set1_1.mp3",
        },
        {
          question: "Apa gejala yang dirasakan pada pasien hipertensi?",
          options: [
            { option: "Mudah Lelah", score: 35 },
            { option: "Sakit kepala", score: 40 }, // correctAnswer
            { option: "Jantung berdebar", score: 25 },
          ],
          correctAnswer: "Sakit kepala",
          sound: "set1_2.mp3",
        },
        {
          question: "Apa yang harus dilakukan pasien hipertensi?",
          options: [
            { option: "Minum obat", score: 40 }, // correctAnswer
            { option: "Mengurangi garam", score: 35 },
            { option: "Olah raga", score: 25 },
          ],
          correctAnswer: "Minum obat",
          sound: "set1_3.mp3",
        },
        {
          question: "Olah raga apa yang menurunkan tekanan darah?",
          options: [
            { option: "Jalan kaki", score: 35 },
            { option: "Peregangan", score: 25 },
            { option: "Aerobik", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Aerobik",
          sound: "set1_4.mp3",
        },
        {
          question:
            "Apa yang harus dilakukan penderita hipertensi agar tekanan darah bisa terkendali baik?",
          options: [
            { option: "Batasi garam", score: 40 }, // correctAnswer
            { option: "Batasi makanan berlemak", score: 35 },
            { option: "Baca label makanan", score: 25 },
          ],
          correctAnswer: "Batasi garam",
          sound: "set1_5.mp3",
        },
      ],
    },
    {
      setNumber: 2,
      questions: [
        {
          question: "Apa gejala hipertensi?",
          options: [
            { option: "Gelisah", score: 30 },
            { option: "Sakit kepala", score: 40 }, // correctAnswer
            { option: "Jantung berdebar", score: 20 },
            { option: "Vertigo", score: 10 },
          ],
          correctAnswer: "Sakit kepala",
          sound: "set2_1.mp3",
        },
        {
          question: "Apa komplikasi dari hipertensi?",
          options: [
            { option: "Gagal ginjal", score: 30 },
            { option: "Kebutaan", score: 20 },
            { option: "Demensia", score: 10 },
            { option: "Stroke", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Stroke",
          sound: "set2_2.mp3",
        },
        {
          question: "Bagaimana pencegahan hipertensi?",
          options: [
            { option: "Olah raga", score: 30 },
            { option: "Gizi seimbang", score: 20 },
            { option: "Kurangi garam", score: 40 }, // correctAnswer
            { option: "Jaga berat badan ideal", score: 10 },
          ],
          correctAnswer: "Kurangi garam",
          sound: "set2_3.mp3",
        },
        {
          question: "Apa jenis pemeriksaan pada pasien hipertensi?",
          options: [
            { option: "Periksa air kemih", score: 30 },
            { option: "Kadar lemak", score: 20 },
            { option: "Jaga berat badan ideal", score: 10 },
            { option: "Rekam jantung", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Rekam jantung",
          sound: "set2_4.mp3",
        },
        {
          question: "Makanan apa yang banyak mengandung garam natrium?",
          options: [
            { option: "Sosis", score: 20 },
            { option: "Ikan asin", score: 40 }, // correctAnswer
            { option: "Biskuit", score: 30 },
            { option: "Jaga berat badan ideal", score: 10 },
          ],
          correctAnswer: "Ikan asin",
          sound: "set2_5.mp3",
        },
      ],
    },
    {
      setNumber: 3,
      questions: [
        {
          question:
            "Makanan berlemak apa yang harus dihindari penderita hipertensi?",
          options: [
            { option: "Daging", score: 30 },
            { option: "Gorengan", score: 40 }, // correctAnswer
            { option: "Kulit", score: 20 },
            { option: "Otak", score: 10 },
          ],
          correctAnswer: "Gorengan",
          sound: "set3_1.mp3",
        },
        {
          question: "Apa yang bisa meningkatkan tekanan darah?",
          options: [
            { option: "Stress", score: 30 },
            { option: "Kurang olah raga", score: 20 },
            { option: "Merokok", score: 10 },
            { option: "Makan tinggi garam", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Makan tinggi garam",
          sound: "set3_2.mp3",
        },
        {
          question: "Apa yang dirasakan pasien hipertensi?",
          options: [
            { option: "Pusing", score: 30 },
            { option: "Penglihatan kabur", score: 20 },
            { option: "Sakit kepala", score: 40 }, // correctAnswer
            { option: "Sesak nafas", score: 10 },
          ],
          correctAnswer: "Sakit kepala",
          sound: "set3_3.mp3",
        },
        {
          question:
            "Makanan apa yang sebaiknya dihindari penderita hipertensi?",
          options: [
            { option: "Makanan olahan", score: 30 },
            { option: "Daging merah", score: 20 },
            { option: "Makanan yang mengandung garam", score: 40 }, // correctAnswer
            { option: "Gorengan", score: 10 },
          ],
          correctAnswer: "Makanan yang mengandung garam",
          sound: "set3_4.mp3",
        },
        {
          question: "Bagaimana upaya mencegah hipertensi?",
          options: [
            { option: "Rutin olah raga", score: 30 },
            { option: "Tidak merokok", score: 20 },
            { option: "Makan buah dan sayur", score: 10 },
            { option: "Mengurangi asupan garam", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Mengurangi asupan garam",
          sound: "set3_5.mp3",
        },
      ],
    },
    {
      setNumber: 4,
      questions: [
        {
          question: "Apa komplikasi hipertensi?",
          options: [
            { option: "Serangan jantung", score: 30 },
            { option: "Stroke", score: 40 }, // correctAnswer
            { option: "Gagal ginjal", score: 20 },
            { option: "Kebutaan", score: 10 },
          ],
          correctAnswer: "Stroke",
          sound: "set4_1.mp3",
        },
        {
          question: "Siapa yang memiliki risiko hipertensi?",
          options: [
            { option: "Riwayat keluarga", score: 30 },
            { option: "Obesitas", score: 20 },
            { option: "Lansia", score: 40 }, // correctAnswer
            { option: "Perokok", score: 10 },
          ],
          correctAnswer: "Lansia",
          sound: "set4_2.mp3",
        },
        {
          question: "Apa dampak dari hipertensi?",
          options: [
            { option: "Gangguan penglihatan", score: 30 },
            { option: "Gangguan syaraf", score: 20 },
            { option: "Penyakit jantung", score: 40 }, // correctAnswer
            { option: "Kematian dini", score: 10 },
          ],
          correctAnswer: "Penyakit jantung",
          sound: "set4_3.mp3",
        },
        {
          question: "Apa kebiasaan sehat untuk mengendalikan tekanan darah?",
          options: [
            { option: "Rutin kontrol", score: 40 }, // correctAnswer
            { option: "Minum obat", score: 30 },
            { option: "Diet", score: 20 },
            { option: "Kelola stress", score: 10 },
          ],
          correctAnswer: "Rutin kontrol",
          sound: "set4_4.mp3",
        },
        {
          question: "Apa tanda tekanan darah tinggi yang berbahaya?",
          options: [
            { option: "Sakit dada", score: 30 },
            { option: "Mata kabur", score: 20 },
            { option: "Lelah", score: 10 },
            { option: "Pusing hebat", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Pusing hebat",
          sound: "set4_5.mp3",
        },
      ],
    },
    {
      setNumber: 5,
      questions: [
        {
          question:
            "Jenis olahraga apa yang dilakukan dengan gerakan berulang untuk menurunkan tekanan darah?",
          options: [
            { option: "Jalan-jalan", score: 30 },
            { option: "Aerobik", score: 40 }, // correctAnswer
            { option: "Lari", score: 20 },
            { option: "Lompat", score: 10 },
          ],
          correctAnswer: "Aerobik",
          sound: "set5_1.mp3",
        },
        {
          question:
            "Cara memasak apa yang harus dihindari bagi penderita hipertensi?",
          options: [
            { option: "Menumis", score: 30 },
            { option: "Santan", score: 20 },
            { option: "Membakar", score: 10 },
            { option: "Menggoreng", score: 40 }, // correctAnswer
          ],
          correctAnswer: "Menggoreng",
          sound: "set5_2.mp3",
        },
        {
          question:
            "Sebutkan makanan yang mengandung alkohol dan harus dihindari penderita hipertensi",
          options: [
            { option: "Durian", score: 40 }, // correctAnswer
            { option: "Nangka", score: 30 },
            { option: "Tape", score: 20 },
            { option: "Sirsak", score: 10 },
          ],
          correctAnswer: "Durian",
          sound: "set5_3.mp3",
        },
        {
          question:
            "Buah apa yang kaya kalium dan baik untuk penderita hipertensi?",
          options: [
            { option: "Pisang", score: 40 }, // correctAnswer
            { option: "Melon", score: 30 },
            { option: "Alpukat", score: 20 },
            { option: "Tomat", score: 10 },
          ],
          correctAnswer: "Pisang",
          sound: "set5_4.mp3",
        },
        {
          question:
            "Jenis bumbu apa yang perlu dibatasi oleh penderita hipertensi?",
          options: [
            { option: "Saus", score: 30 },
            { option: "Kecap", score: 40 }, // correctAnswer
            { option: "Penyedap", score: 20 },
            { option: "Terasi", score: 10 },
          ],
          correctAnswer: "Kecap",
          sound: "set5_5.mp3",
        },
      ],
    },
  ],
};

export default questionData;
