const wordGuessQuestions = {
  sets: [
    {
      setNumber: 1,
      questions: [
        {
          question: "Komplikasi hipertensi",
          answer: "Stroke",
        },
        {
          question: "Tanda gejala hipertensi pada mata",
          answer: "Kabur",
        },
        {
          question:
            "Keadaan peningkatan pada tekanan darah sistolik >140 mmHg atau tekanan darah diastolik >90 mmHg",
          answer: "Hipertensi",
        },
        {
          question: "Salah satu faktor risiko hipertensi",
          answer: "Stres",
        },
        {
          question: "Meningkatkan tekanan darah dengan cepat",
          answer: "Natrium",
        },
      ],
    },
    {
      setNumber: 2,
      questions: [
        {
          question: "Harus dilakukan setiap bulan",
          answer: "Kontrol",
        },
        {
          question: "Aktivitas berupa gerakan yang diulang-ulang",
          answer: "Aerobik",
        },
        {
          question:
            "Gerakan tanpa menggerakkan sendi yang dapat menurunkan tekanan darah",
          answer: "Isometrik",
        },
        {
          question: "Cara kelola stres pada pasien hipertensi",
          answer: "Meditasi",
        },
        {
          question: "Faktor risiko hipertensi yang tidak dapat diubah",
          answer: "Genetik",
        },
      ],
    },
    {
      setNumber: 3,
      questions: [
        {
          question:
            "Tekanan yang ditimbulkan saat jantung berdetak untuk memompa atau mendorong darah ke seluruh tubuh",
          answer: "Sistolik",
        },
        {
          question:
            "Jenis hipertensi yang tidak diketahui penyebabnya adalah hipertensi",
          answer: "Primer",
        },
        {
          question:
            "Salah satu komplikasi hipertensi yaitu berupa perdarahan akibat pecahnya pembuluh darah di dalam otak",
          answer: "Stroke",
        },
        {
          question:
            "Jenis makanan yang harus dibatasi oleh penderita hipertensi",
          answer: "Garam",
        },
        {
          question: "Minuman yang berbahaya bagi penderita hipertensi",
          answer: "Alkohol",
        },
      ],
    },
    {
      setNumber: 4,
      questions: [
        {
          question: "Kondisi tekanan darah yang terus-menerus tinggi",
          answer: "Hipertensi",
        },
        {
          question:
            "Jenis hipertensi yang terjadi tanpa penyebab jelas",
          answer: "Primer",
        },
        {
          question: "Pembuluh darah yang membawa darah kembali ke jantung",
          answer: "Vena",
        },
        {
          question: "Makanan yang harus dibatasi untuk mencegah hipertensi",
          answer: "Lemak",
        },
        {
          question:
            "Minuman ini sebaiknya dikurangi konsumsinya oleh penderita hipertensi",
          answer: "Kopi",
        },
      ],
    },
    {
      setNumber: 5,
      questions: [
        {
          question: "Unsur ini harus dikurangi pada pasien hipertensi",
          answer: "Sodium",
        },
        {
          question: "Jenis lemak yang perlu dihindari",
          answer: "Jenuh",
        },
        {
          question: "Penyakit yang dapat diakibatkan oleh hipertensi",
          answer: "Stroke",
        },
        {
          question: "Organ yang dapat rusak karena hipertensi",
          answer: "Jantung",
        },
        {
          question: "Menjadi faktor risiko hipertensi",
          answer: "Obesitas",
        },
      ],
    },
    {
      setNumber: 6,
      questions: [
        {
          question:
            "Kandungan dalam makanan olahan yang menjadi risiko hipertensi",
          answer: "Natrium",
        },
        {
          question:
            "Bagian tubuh yang sering terasa sakit saat tekanan darah sangat tinggi",
          answer: "Kepala",
        },
        {
          question: "Perilaku yang dapat meningkatkan tekanan darah",
          answer: "Marah",
        },
        {
          question: "Makanan yang kaya serat dan baik untuk hipertensi",
          answer: "Kacang",
        },
        {
          question: "Buah yang dapat menurunkan tekanan darah",
          answer: "Alpukat",
        },
      ],
    },
  ],
};

export default wordGuessQuestions;
