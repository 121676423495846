import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Seimbang from '../assets/seimbang.png';
import Sayur from '../assets/sayur.png';
import Pinggang from '../assets/pinggang.jpeg';
import Olahraga from '../assets/olahraga.png';
import Alkohol from '../assets/alcohol.webp';
import Merokok from '../assets/merokok.png';
import Stres from '../assets/stres.webp';
import { BsArrowLeftCircleFill, BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab5Stage1 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab5Stage1 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:5, stage:1},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[5][1] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/6/1');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Cara Mencegah Hipertensi</h2>
          <button onClick={() => navigate('/edukasi/6/1')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 pt-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-4">Bagaimana Cara Mencegah agar tidak Hipertensi?</h3>
          
          <div className="text-gray-700 text-lg grid grid-cols-1 gap-4">
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Memenuhi Gizi Seimbang dengan Membatasi Garam dan Lemak</b>
            <br/>
            <img
                src={Seimbang}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Perbanyak Makan Sayur dan Buah</b>
            <br/>
            <img
                src={Sayur}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Mempertahankan BB ideal dan lingkar
            pinggang.</b>
            <br/>
            Pria &lt;90cm dan wanita &lt;80 cm
            <img
                src={Pinggang}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Olahraga Teratur</b>
            <br/>
            <img
                src={Olahraga}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Hindari Konsumsi Alkohol</b>
            <br/>
            <img
                src={Alkohol}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Stop Merokok</b>
            <br/>
            <img
                src={Merokok}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
          <div className='border rounded-lg p-4 shadow-lg'>
            <b>Kelola Stres</b>
            <br/>
            <img
                src={Stres}
                alt="Pressure Meter"
                className="w-auto my-2 h-32 object-cover mx-auto"
            />
            </div>
        </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
        <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/4/1')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab5Stage1}>
            {lanjut}
          </button>
          </div>
      </div>
    </Platform>
  );
};

export default Bab5Stage1;
