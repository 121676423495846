import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab6Stage6 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selesai");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab6Stage6 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:6, stage:6},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[6][6] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selesai");

      navigate('/island');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Cara Mengendalikan Hipertensi</h2>
          <button onClick={() => navigate('/island')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">6. Kelola Stres</h3>
          <div className="text-gray-700 text-lg mb-6">
            <b>Mengapa penderita hipertensi  tidak boleh stress?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li>Stres menyebabkan peningkatan tegangan pembuluh darah dan jantung sehingga tekanan darah semakin tinggi.</li>
            </ul>
          </div>
          <div className="text-gray-700 text-lg mb-6">
            <b>Bagaimana cara mengendalikan stres?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li>Lakukan relaksasi dengan meditasi, nafas dalam dan relaksasi otot progresif.</li>
              <li>Hindari pertengkaran dan kekerasan.</li>
              <li>Hindari kebisingan.</li>
              <li>Luangkan waktu untuk rekreasi.</li>
              <li>Lakukan kegiatan sebagai hobi/kesenangan.</li>
              <li>Konsultasi psikologis jika ada permasalahan yang tidak bisa terselesaikan.</li>
              <li>Jangan lupa berdoa.</li>
            </ul>
          </div>
          <div className="text-gray-700 text-lg mb-6">
            <b>Apa bahayanya alkohol bagi penderita hipertensi?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li>Alkohol merusak pembuluh darah sehingga tekanan darah</li>
            </ul>
          </div>
          <div className="text-gray-700 text-lg mb-6">
            <b>Apa untungnya jika tidak mengkonsumsi alkohol?</b>
            <ul className='mx-8' style={{listStyleType: 'circle'}}>
              <li>Tekanan darah akan menurun bahkan hingga hingga 4 mmHg.</li>
            </ul>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
        <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/6/5')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab6Stage6}>
            {lanjut}
          </button>
          </div>
      </div>
    </Platform>
  );
};

export default Bab6Stage6;
