import React, { useState } from "react";
import Platform from "./platform";

const EditProfilePage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const handleSaveChanges = (e) => {
    e.preventDefault();
    // Handle saving the changes here
    console.log("Saving changes:", { username, email });
  };

  return (
    <Platform>
      <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
        {/* Header */}
        <div className="flex items-center justify-between w-full max-w-md py-4">
          <button className="text-pink-700 text-2xl font-bold">‹</button>
          <h1 className="text-3xl font-bold text-pink-700">MaTaHaTi</h1>
          <div></div> {/* Empty div for spacing */}
        </div>

        {/* Title and Subtitle */}
        <div className="text-center my-4">
          <h2 className="text-2xl font-bold text-pink-700">
            Edit pengaturan akun
          </h2>
          <p className="text-sm text-gray-600">
            Perbarui email atau edit namamu
          </p>
        </div>

        {/* Form */}
        <form onSubmit={handleSaveChanges} className="w-full max-w-md mt-4">
          {/* Username Field */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-1">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="username"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-pink-500"
            />
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email_anda@gmail.com"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-pink-500"
            />
          </div>

          {/* Save Changes Button */}
          <button
            type="submit"
            className="w-full bg-pink-500 text-white font-bold py-3 rounded-full hover:bg-pink-600 mt-4"
          >
            Simpan Perubahan
          </button>
        </form>
      </div>
    </Platform>
  );
};

export default EditProfilePage;
