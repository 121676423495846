import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoBookOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BrokenHeart from "../assets/broken-heart.png";

const Modal = ({ setOpenPopup }) => {
  const navigate = useNavigate();
  const Button = ({ content, onClick, bgColor }) => {
    return (
      <button 
        onClick={onClick}
        className={`bg-${bgColor} text-white shadow-inner 
        shadow-black w-full py-4 rounded-full font-normal text-xl`}
      >
        {content}
      </button>
    );
  };

  return (
    <div 
      className="
        fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 
        w-full h-full bg-black bg-opacity-50
        flex items-center justify-center z-50
      ">
      <div className="bg-white w-80 text-matahati-primary-400 px-8 py-12 rounded-3xl shadow-lg">
        <p className="capitalize text-4xl text-matahati-primary-400 mb-2 text-center font-bold">Mau keluar?</p>
        <p className="mb-4 text-center">Kamu akan kalah!</p>
        <img src={BrokenHeart} alt="Broken Heart" className="w-32 h-auto mx-auto mb-4" />
        <div className="flex space-x-4">
          <Button 
            content="Ok"
            onClick={() => {navigate('/island'); setOpenPopup(false)}}
            bgColor='matahati-primary-400'
          />
          <Button 
            content="Batal"
            onClick={() => setOpenPopup(false)}
            bgColor='matahati-secondary-200'
          />
        </div>
      </div>
    </div>
  );
};

const BackButton = ({ title, to = "/edukasi/1/1" }) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div className="relative flex justify-between items-center w-full mb-6 mt-2">
      <div 
        onClick={() => setOpenPopup(true)} 
        className="font-bold text-white flex flex-col text-xl cursor-pointer shadow-md shadow-matahati-primary-500 w-fit px-2 h-12 items-center justify-center bg-matahati-primary-400 rounded-lg"
      >
        <IoIosArrowBack />
        <p className="text-xs">Kembali</p>
      </div>
      <h2 className="text-xl font-bold capitalize text-white underline underline-offset-4">{title}</h2>
      <a 
        href={to} 
        className="font-bold text-white flex flex-col text-xl cursor-pointer shadow-md shadow-matahati-primary-500 w-fit px-2 h-12 items-center justify-center bg-matahati-primary-400 rounded-lg"
      >
        <IoBookOutline />
        <p className="text-xs">Belajar</p>
      </a>
      {openPopup && (
        <Modal setOpenPopup={setOpenPopup} />
      )}
    </div>
  );
};

export default BackButton;

