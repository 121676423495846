const pictGuessQuestions = {
  sets: [
    {
      setNumber: 1,
      questions: [
        {
          question: "Makanan yang banyak mengandung garam natrium",
          answer: "Biskuit",
          imageKey: "Tebak_Gambar_1.png",
        },
        {
          question: "Faktor risiko hipertensi",
          answer: "Obesitas",
          imageKey: "Tebak_Gambar_2.png",
        },
        {
          question: "Olahraga yang bisa dilakukan oleh penderita hipertensi",
          answer: "jalan kaki",
          imageKey: "Tebak_Gambar_3.png",
        },
        {
          question: "Makanan yang mengandung alkohol",
          answer: "durian",
          imageKey: "Tebak_Gambar_4.png",
        },
        {
          question: "Makanan yang dihindari pada pasien hipertensi",
          answer: "Ikan asin",
          imageKey: "Tebak_Gambar_5.png",
        },
      ],
    },
    {
      setNumber: 2,
      questions: [
        {
          question: "Unsur berbahaya dalam rokok yang menyebabkan hipertensi",
          answer: "Nikotin",
          imageKey: "Tebak_Gambar_11.png",
        },
        {
          question: "Cara menghilangkan stres untuk pasien hipertensi",
          answer: "Meditasi",
          imageKey: "Tebak_Gambar_12.png",
        },
        {
          question: "Cara memasak makanan untuk pasien hipertensi",
          answer: "Merebus",
          imageKey: "Tebak_Gambar_13.png",
        },
        {
          question: "Tanda hipertensi",
          answer: "Mata kabur",
          imageKey: "Tebak_Gambar_14.png",
        },
        {
          question: "Makanan apa yang dapat meningkatkan tekanan darah?",
          answer: "sate kambing",
          imageKey: "Tebak_Gambar_15.png",
        },
      ],
    },
    {
      setNumber: 3,
      questions: [
        {
          question: "Makanan yang banyak mengandung natrium",
          answer: "Sarden",
          imageKey: "Tebak_Gambar_6.png",
        },
        {
          question: "Makanan yang mengandung lemak",
          answer: "Bakso",
          imageKey: "Tebak_Gambar_7.png",
        },
        {
          question: "Faktor risiko hipertensi",
          answer: "Garam",
          imageKey: "Tebak_Gambar_8.png",
        },
        {
          question: "Minuman sehat untuk pasien hipertensi",
          answer: "air putih",
          imageKey: "Tebak_Gambar_9.png",
        },
        {
          question: "Gaya hidup yang berisiko hipertensi",
          answer: "Merokok",
          imageKey: "Tebak_Gambar_10.png",
        },
      ],
    },
    {
      setNumber: 4,
      questions: [
        {
          question: "Kegiatan untuk menurunkan hipertensi",
          answer: "Yoga",
          imageKey: "Tebak_Gambar_16.png",
        },
        {
          question: "Bumbu yang perlu dihindari untuk penderita hipertensi",
          answer: "Kecap",
          imageKey: "Tebak_Gambar_17.png",
        },
        {
          question: "Bumbu yang perlu untuk penderita hipertensi",
          answer: "Garam",
          imageKey: "Tebak_Gambar_18.png",
        },
        {
          question: "Makanan yang dapat meningkatkan tekanan darah",
          answer: "Sarden",
          imageKey: "Tebak_Gambar_19.png",
        },
        {
          question:
            "Bagaimana cara memasak makanan yang disarankan untuk hipertensi?",
          answer: "Rebus",
          imageKey: "Tebak_Gambar_20.png",
        },
      ],
    },
    {
      setNumber: 5,
      questions: [
        {
          question: "Sayuran yang dapat menurunkan tekanan darah adalah",
          answer: "Bayam",
          imageKey: "Tebak_Gambar_21.png",
        },
        {
          question: "Pasien hipertensi harus patuh",
          answer: "Minum Obat",
          imageKey: "Tebak_Gambar_22.png",
        },
        {
          question: "Jenis aktivitas yang dilakukan untuk hipertensi",
          answer: "senam",
          imageKey: "Tebak_Gambar_23.png",
        },
        {
          question: "Tanda dan gejala hipertensi",
          answer: "Kesemutan",
          imageKey: "Tebak_Gambar_24.png",
        },
        {
          question: "Penyebab pembuluh darah menyempit",
          answer: "Kol este rol",
          imageKey: "Tebak_Gambar_25.png",
        },
      ],
    },
  ],
};

export default pictGuessQuestions;
