import React, { useState, useEffect, useRef } from "react";
import Platform from "./platform";
import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import backgroundUrl from "../assets/background_with_object.png";
import { PopupTutorial, PopupReaction } from "../components/Popup";
import surveyData from "../data/surveyData"; // Import dataset
import { FaCirclePlay } from "react-icons/fa6";
import ResultComponent from "../components/Result";
import { api, getAuthHeader } from "../controllers/getUserInfo";
import fetchPackage from "../controllers/getLevelToPlay";
import sound_1 from "../assets/sounds/survey100/sound1.mp3";
import sound_2 from "../assets/sounds/survey100/sound2.mp3";
import sound_3 from "../assets/sounds/survey100/sound3.mp3";
import sound_4 from "../assets/sounds/survey100/sound4.mp3";
import sound_5 from "../assets/sounds/survey100/sound5.mp3";
import sound_6 from "../assets/sounds/survey100/sound6.mp3";
import sound_7 from "../assets/sounds/survey100/sound7.mp3";
import sound_8 from "../assets/sounds/survey100/sound8.mp3";
import sound_9 from "../assets/sounds/survey100/sound9.mp3";
import sound_10 from "../assets/sounds/survey100/sound10.mp3";
import sound_11 from "../assets/sounds/survey100/sound11.mp3";
import sound_12 from "../assets/sounds/survey100/sound12.mp3";
import sound_13 from "../assets/sounds/survey100/sound13.mp3";
import sound_14 from "../assets/sounds/survey100/sound14.mp3";
import sound_15 from "../assets/sounds/survey100/sound15.mp3";
import sound_16 from "../assets/sounds/survey100/sound16.mp3";
import sound_17 from "../assets/sounds/survey100/sound17.mp3";
import sound_18 from "../assets/sounds/survey100/sound18.mp3";
import sound_19 from "../assets/sounds/survey100/sound19.mp3";
import sound_20 from "../assets/sounds/survey100/sound20.mp3";
import sound_21 from "../assets/sounds/survey100/sound21.mp3";
import sound_22 from "../assets/sounds/survey100/sound22.mp3";
import synonymDict from "../data/synonymDict";

const QuestionGame = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [revealedAnswers, setRevealedAnswers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const [isCorrect, setIsCorrect] = useState(false);
  const [questionPackage, setQuestionPackage] = useState(0);
  const [isInCorrect, setIsInCorrect] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const audioRef = useRef(null);
  const [soundFile, setSoundFile] = useState("");
  const sounds = {
    "sound1.mp3": sound_1,
    "sound2.mp3": sound_2,
    "sound3.mp3": sound_3,
    "sound4.mp3": sound_4,
    "sound5.mp3": sound_5,
    "sound6.mp3": sound_6,
    "sound7.mp3": sound_7,
    "sound8.mp3": sound_8,
    "sound9.mp3": sound_9,
    "sound10.mp3": sound_10,
    "sound11.mp3": sound_11,
    "sound12.mp3": sound_12,
    "sound13.mp3": sound_13,
    "sound14.mp3": sound_14,
    "sound15.mp3": sound_15,
    "sound16.mp3": sound_16,
    "sound17.mp3": sound_17,
    "sound18.mp3": sound_18,
    "sound19.mp3": sound_19,
    "sound20.mp3": sound_20,
    "sound21.mp3": sound_21,
    "sound22.mp3": sound_22,
  };

  const getUserInfo = async () => {
    try {
      const response = await api.get("/api/user", {
        headers: getAuthHeader(),
      });
      console.log("User Info:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Failed to fetch user info:",
        error.response ? error.response.status : error.message
      );
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const loadQuestionSet = async (setIndex) => {
    const dataQuestion = surveyData;
    const questionSet = dataQuestion.sets[setIndex].data;
    setQuestions(questionSet);
    setSoundFile(questionSet.soundFile);
    setRevealedAnswers(new Array(questionSet.answers.length).fill(false));
    setInputValue("");
    setScore(0);
    setAttempts(0);
    setMessage("");
  };

  const handleSkip = () => {
    const previousData = JSON.parse(localStorage.getItem("user_data"));

    // Mulai dari soal berikutnya
    let nextPackage = questionPackage;
    console.log(previousData.survey100[nextPackage]);

    // Periksa apakah set soal berikut valid dan soal itu sudah dilewati sebelumnya
    while (
      nextPackage < surveyData.sets.length &&
      previousData.survey100[nextPackage] &&
      previousData.survey100[nextPackage].pass === true
    ) {
      nextPackage++;
    }

    // Pastikan bahwa `nextPackage` tidak melebihi panjang array `surveyData.sets`
    if (nextPackage < surveyData.sets.length) {
      nextPackage++;
      setQuestionPackage(nextPackage);
      loadQuestionSet(nextPackage);
    } else {
      console.log("Tidak ada set soal berikutnya yang tersedia.");
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userData = await getUserInfo();
      setUserInfo(userData);
      const set = await fetchPackage("survey100", "/game/survey100");
      setQuestionPackage(set - 1);
      loadQuestionSet(set - 1);
      console.log(questions);
    };

    fetchUserInfo();
    setIsCorrect(false);
    setIsInCorrect(false);
  }, []);

  useEffect(() => {
    if (gameOver) {
      const storeNewScore = async () => {
        try {
          const userId = userInfo.id;
          const accuracy = score.toFixed(0);
          console.log(accuracy);
          const completed = accuracy >= 80;

          const response = await api.post(
            "/api/post-survey-100",
            {
              user_id: userId,
              set: questionPackage + 1,
              score: accuracy,
              completed: completed,
            },
            {
              headers: getAuthHeader(),
            }
          );

          const previousData = JSON.parse(localStorage.getItem("user_data"));
          if (!previousData) {
            navigate("/login");
          }
          previousData.survey100[questionPackage + 1].pass = completed;
          previousData.survey100[questionPackage + 1].score = score;
          localStorage.setItem("user_data", JSON.stringify(previousData));
          console.log(response.data);
        } catch (error) {
          console.error(
            "Failed to fetch user info:",
            error.response ? error.response.status : error.message
          );
          if (error.response && error.response.status === 401) {
            navigate("/login");
          }
        }
      };
      storeNewScore();
    }
  }, [gameOver, userInfo]);

  useEffect(() => {
    if (
      attempts >= 8 ||
      (questions && revealedAnswers.every((answer) => answer))
    ) {
      setGameOver(true);
    }
  }, [attempts, revealedAnswers, questions]);

  useEffect(() => {
    if (isCorrect || isInCorrect) {
      const timer = setTimeout(() => {
        setIsCorrect(false);
        setIsInCorrect(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isCorrect, isInCorrect]);

  const handleInputChange = (e) => setInputValue(e.target.value);

  const levenshteinDistance = (a, b) => {
    const matrix = Array.from({ length: a.length + 1 }, () =>
      Array(b.length + 1).fill(0)
    );

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        if (a[i - 1] === b[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j - 1] + 1
          );
        }
      }
    }

    return matrix[a.length][b.length];
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (questions) {
      const checkAnswer = (input, answer) => {
        const threshold = 2;
        if (input.toLowerCase() === answer.toLowerCase()) return true;
        const synonyms = synonymDict[answer] || [];
        const allOptions = [answer, ...synonyms];

        return allOptions.some((option) => {
          const distance = levenshteinDistance(
            input.toLowerCase(),
            option.toLowerCase()
          );
          return distance <= threshold;
        });
      };

      const answerIndex = questions.answers.findIndex((answer) =>
        checkAnswer(inputValue, answer)
      );

      if (answerIndex !== -1 && !revealedAnswers[answerIndex]) {
        const newRevealedAnswers = [...revealedAnswers];
        newRevealedAnswers[answerIndex] = true;
        setRevealedAnswers(newRevealedAnswers);
        setIsCorrect(true);
        setIsInCorrect(false);
        setScore((prevScore) => prevScore + questions.scores[answerIndex]);
        setMessage("");
      } else {
        setIsCorrect(false);
        setIsInCorrect(true);
        setMessage("Jawaban Tidak Ada!");
      }

      setInputValue("");
      setAttempts((prevAttempts) => prevAttempts + 1);
    }
  };

  const playAudio = async () => {
    if (audioRef.current) {
      audioRef.current.src = sounds[questions.soundFile];
      console.log(audioRef.current.src);
      try {
        await audioRef.current.play();
      } catch (error) {
        console.log("Audio play error:", error);
      }
    }
  };

  const handlePlay = () => {
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    navigate("/island");
  };

  if (!questions) return null;

  const correctAnswers = revealedAnswers.filter(Boolean).length;
  const incorrectAnswers = questions.answers.length - correctAnswers;
  const accuracy = score.toFixed(0);

  return (
    <Platform>
      <div
        className="relative flex flex-col items-center justify-center h-screen text-white"
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {showPopup && (
          <PopupTutorial
            title="Survei 100"
            description="Pemain harus menebak jawaban berdasarkan survei yang diberikan kepada 100 orang. Ada 10 kesempatan untuk menebak 5 jawaban terpopuler."
            buttonText="Main"
            handleClose={handleClosePopup}
            handleAction={handlePlay}
          />
        )}
        {isCorrect && <PopupReaction isCorrect={true} />}
        {isInCorrect && <PopupReaction isCorrect={false} />}

        {!showPopup && !gameOver && (
          <>
            <div className="absolute top-0 w-full p-8">
              <BackButton to={"/"} title={"Survey100"} />
            </div>
            <div className="mb-4 text-sm font-bold text-white">
              Kesempatan Tersisa: {8 - attempts}
            </div>
            <div className="w-80 max-w-md bg-white rounded-lg text-pink-800 font-bold p-4 mb-4 flex items-center justify-between space-x-4">
              <span className="text-2xl">{questions.question}</span>

              <button
                onClick={playAudio}
                className="bg-matahati-primary-100 text-white font-bold p-2 rounded-full"
              >
                <FaCirclePlay className="text-matahati-primary-300 h-8 w-8 hover:bg-matahati-primary-200 rounded-full" />
              </button>
            </div>

            <div className="w-80 max-w-md bg-pink-200 rounded-lg p-4 space-y-2">
              {questions.answers.map((answer, index) => (
                <div
                  key={index}
                  className={`flex justify-between items-center py-2 px-4 font-bold text-xl rounded-lg ${
                    revealedAnswers[index]
                      ? "bg-pink-400 text-pink-900"
                      : "bg-pink-100 text-pink-400"
                  }`}
                >
                  <span>{revealedAnswers[index] ? answer : ""}</span>
                  <span>
                    {revealedAnswers[index] ? `${questions.scores[index]}` : ""}
                  </span>
                </div>
              ))}
            </div>

            {message && (
              <div className="mt-4 text-red-500 font-bold">{message}</div>
            )}

            <form onSubmit={handleSubmit} className="mt-6 w-80 max-w-md">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Masukkan Jawaban ..."
                className="w-full p-2 rounded-lg text-gray-700 text-center text-lg bg-white mb-2"
              />
              <button
                type="submit"
                className="w-full bg-matahati-secondary-200 text-white font-bold py-2 rounded-lg hover:bg-matahati-secondary-300"
              >
                Kirim
              </button>
            </form>

            <button
              onClick={handleSkip}
              className="mt-4 w-80 bg-matahati-primary-400 text-white font-bold py-2 rounded-lg hover:bg-matahati-primary-500"
            >
              Lewati Soal Ini
            </button>
          </>
        )}

        {gameOver && (
          <ResultComponent
            origin="/game/survey100"
            score={accuracy}
            correct={correctAnswers}
            incorrect={incorrectAnswers}
          />
        )}
        <audio ref={audioRef} />
      </div>
    </Platform>
  );
};

export default QuestionGame;
