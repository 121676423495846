import React, { useEffect, useState } from "react";
import Platform from "./platform";
import HeartBar from "../components/HeartBar";
import DoubleOptionButtons from "../components/OptionButton";
import handleTfAnswer from "../controllers/handleAnswers";
import questionTrueFalse from "../data/trueFalseGameData";
import BackButton from "../components/BackButton";
import BottomNavBar from "./island/BottomNavBar";
import QuestionMark from "../assets/questionmarks.png";
import {PopupTutorial, PopupReaction, ChooseLevel} from "../components/Popup";
import ResultComponent from "../components/Result";
import { api, getAuthHeader } from "../controllers/getUserInfo";
import { useNavigate } from "react-router-dom";
import fetchPackage from "../controllers/getLevelToPlay";

const TrueFalseGame = () => {
  const [remainHP, setRemainHP] = useState(20);
  const [totalHP, setTotalHP] = useState(100);
  const [question, setQuestion] = useState([]);
  const [questionPackage, setQuestionPackage] = useState(0);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [answerStatus, setAnswerStatus] = useState(Array(10).fill(null));
  const [isDone, setIsDone] = useState(false);
  const [popupTutorial, setPopupTutorial] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isInCorrect, setIsInCorrect] = useState(false);
  const [popupLevel, setPopupLevel] = useState(false);
  const navigate = useNavigate();

  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };
  
  useEffect(() => {   

    const fetchUserInfo = async () => {
      const userData = await getUserInfo();
      setUserInfo(userData);
      const set = await fetchPackage('mitos_fakta', '/game/true-false');
      if(set) {
        setQuestionPackage(set);
        setQuestion(dataQuestion.sets[set-1].questions);
      } else {
        setPopupLevel(true);
        setPopupTutorial(false);
      }
    };

    fetchUserInfo();
    const dataQuestion = questionTrueFalse
    setCurrentNumber(0);
    setCorrectAnswer(0)
    setTotalHP(180);
    setRemainHP(110);
    setAnswerStatus(Array(10).fill(null));
    setIsCorrect(false);
    setIsInCorrect(false);
    setIsDone(false);

  }, []);

  const handlePlay = () => {
    setPopupTutorial(false);
  };

  const handleClosePopup = () => {
    setPopupTutorial(false);
  };

  const handleCloseLevel = () => {
    setPopupLevel(false);
  }

  if (isDone) {
    const storeNewScore = async () => {
      try {
        const userId = userInfo.id;
        const score = correctAnswer * 10;

        const response = await api.post('/api/post-mitos-fakta', 
          { user_id:userId, set:questionPackage, score:score, completed: score >= 80},
          {
            headers: getAuthHeader()
        });

        const previousData = JSON.parse(localStorage.getItem('user_data'));
        if (!previousData) {
          navigate('/login');
        }
                    //atur nama gamenya di bawah ini
        previousData.mitos_fakta[questionPackage].pass = score >= 80; //set threshold KKM di sini
        previousData.mitos_fakta[questionPackage].score = score; //set score baru di sini
        localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

        console.log('User Info:', response.data);
          
      } catch (error) {
          console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
      }
    }
    storeNewScore();
  }
  
  return (
    <Platform>
      <div className="relative w-full h-full overflow-hidden bg-matahati-primary-300">
        {isDone ? (
          <ResultComponent 
            origin={'/game/true-false'}
            score={correctAnswer * 10}
            correct={correctAnswer}
            incorrect={question.length - correctAnswer}
          />
        ) : (
          <div className="w-full h-full px-8 py-6">
            {popupTutorial && (
              <PopupTutorial
                title="Mitos/Fakta"
                description="Pemain harus menebak apakah pertanyaan yang diberikan adalah mitos atau fakta. Pemain harus memilih jawaban dalam batas waktu yang ditentukan."
                buttonText="Play"
                handleClose={handleClosePopup}
                handleAction={handlePlay}
              />
            )}
            {popupLevel && (
              <ChooseLevel 
                handleClose={handleCloseLevel}
                gameName={'mitos_fakta'}
                questionsData={questionTrueFalse}
                questionState={setQuestion}
              />
            )}
            {isCorrect && (
              <PopupReaction
                isCorrect={true}
              />
            )}
            {isInCorrect && (
              <PopupReaction
                isCorrect={false}
              />
            )}
            <BackButton title={'Mitos / Fakta'}/>
            <div className="flex gap-1 mb-5 w-full">
              {!isDone && answerStatus.map((status, index) => (
                <div 
                  key={index} 
                  className={`
                    h-7 w-7 flex items-center justify-center text-sm rounded-lg border-2 text-white 
                    ${status === true ? 'bg-matahati-secondary-200 ' 
                      : status === false ? 'bg-matahati-primary-500 ' 
                      : 'bg-transparent'}
                    ${index === currentNumber ? 'border-matahati-primary-500' : 'border-white'}
                  `}
                > 
                  {index + 1}
                </div>   
              ))}
            </div>
            <div className="flex flex-col h-full items-center gap-4">
              <div className="w-full">
                <HeartBar 
                  remain={remainHP}
                  total={totalHP}
                />
              </div>
              {question.length > 0 && (
                <>
                  <img src={QuestionMark} className="mx-auto -mb-8 w-56 h-auto" alt="" />
                  <div className="flex items-center h-44 rounded-3xl px-4 bg-white">
                    <p className="text-xl text-center text-matahati-primary-500">{question[currentNumber].question}</p>
                  </div>
                  <div className="flex w-full gap-4">
                    <DoubleOptionButtons 
                      content={'Mitos'} 
                      onClick={() => handleTfAnswer(
                        question[currentNumber].answer === 'Mitos', 
                        totalHP,
                        question.length, 
                        setRemainHP, 
                        setCurrentNumber,
                        setCorrectAnswer,
                        setIsDone,
                        setAnswerStatus,
                        setIsCorrect,
                        setIsInCorrect
                      )()} 
                      bgcolor={'matahati-primary-400'} 
                    />
                    <DoubleOptionButtons 
                      content={'Fakta'} 
                      onClick={() => handleTfAnswer(
                        question[currentNumber].answer === 'Fakta', 
                        totalHP,
                        question.length, 
                        setRemainHP, 
                        setCurrentNumber,
                        setCorrectAnswer,
                        setIsDone,
                        setAnswerStatus,
                        setIsCorrect,
                        setIsInCorrect
                      )()} 
                      bgcolor={'matahati-secondary-200'} 
                    />
                  </div>
                </>
              )}
            </div>
            <BottomNavBar />
          </div>
        )}
      </div>
    </Platform>
  );  
}

export default TrueFalseGame;