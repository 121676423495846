import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Photo from '../assets/bab1stage3.jpg';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab1Stage3 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab1Stage3 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:1, stage:3},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[1][3] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/2/1');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Hipertensi</h2>
          <button className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold" onClick={() => navigate('/edukasi/2/1')}>
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Ada Berapa Jenis Hipertensi?</h3>
          <img
            src={Photo}
            alt="Pressure Meter"
            className="w-full my-4 h-64 object-cover"
          />
          <div className='text-gray-700 text-lg'>
          <ol type='1'>
            <li className='font-bold'>1. Hipertensi Primer</li>
            <ul className='mx-8 my-4' style={{listStyleType: 'circle'}}>
                <li>Hipertensi primer merupakan jenis hipertensi yang tidak diketahui penyebabnya.</li>
                <li>Hipertensi primer sering tidak menimbulkan gejala.</li>
                <li>Jenis hipertensi ini tidak dapat diobati, tetapi dapat dikontrol. Hipertensi primer sering diturunkan dalam suatu keluarga.</li>
                <li>Sebagian besar pasien hipertensi termasuk jenis hipertensi primer bahkan lebih &gt;90% pasien hipertensi menderita hipertensi primer. </li>
                <li>Hipertensi primer dapat juga dipengaruhi oleh faktor gaya hidup seperti : Obesitas, Merokok, Alkohol, Kurang aktivitas , Diet tinggi garam</li>
            </ul>
            <li className='font-bold'>2. Hipertensi Sekunder</li>
            <ul className='mx-8 my-4' style={{listStyleType: 'circle'}}>
                <li>Hipertensi sekunder terjadi akibat komplikasi dari penyakit lain diantaranya : diabetes, penyakit ginjal, penyakit pembuluh darah dan lainnya.</li>
                <li>Hipertensi sekunder menimbulkan gejala yang nyata.</li>
                <li>Hipertensi sekunder dapat sembuh jika penyakit lain yang menjadi penyebabnya disembuhkan.</li>
            </ul>
          </ol>
          </div>
          {/* tambahkan sebuah quotes di bawah ini */}
            <div className='bg-matahati-primary-400 text-white py-4 px-8 rounded-lg mt-4'>
                <p className='italic text-lg font-semibold'>Hipertensi sering disebut
sebagai <b>PEMBUNUH DALAM 
SENYAP</b> karena sering timbul
tanpa gejala sehingga
penderita tidak tahu kalau
mengalami hipertensi yang 
dapat menimbulkan
kematian.</p>
                </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/1/2')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab1Stage3}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab1Stage3;
