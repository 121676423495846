import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Photo from '../assets/pembuluh-darah.jpeg';
import Makanan1 from '../assets/makanan-1.jpg';
import Garam from '../assets/garam.jpg';
import Merebus from '../assets/air.jpg';
import LabelFood from '../assets/label.png';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab6Stage3 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab6Stage3 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:6, stage:3},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[6][3] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/6/4');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Cara Mengendalikan Hipertensi</h2>
          <button onClick={() => navigate('/island')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 py-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">3. Tetap Batasi Makanan Lemak dan Garam</h3>
          
          <div className="text-gray-700 text-lg">
          <b>Apakah penderita hipertensi harus membatasi makanan yang mengandung lemak?</b>
          
          <ul className='mx-8' style={{listStyleType: 'circle'}}>
            <li>Lemak dalam tubuh dapat menumpuk di
pembuluh darah arteri sehingga terjadi
penyempitan. Akibatnya tekanan darah akan
semakin tinggi, bahkan bisa menyebabkan
pembuluh darah bisa pecah. Bisa
menyebabkab kematian mendadak</li>
          </ul>
          </div>
          <img
            src={Photo}
            alt="Pressure Meter"
            className="w-auto my-4 h-full object-cover"
          />
          <div className="text-gray-700 text-lg">
          <b>Apakah penderita hipertensi harus membatasi makanan yang mengandung garam?</b>
          
          <ul className='mx-8' style={{listStyleType: 'circle'}}>
            <li>Ya. Penderita hipertensi harus mengurangi
            garam. </li>
            <li>Garam dapat menyebabkan jumlah darah
dalam pembuluh darah menjadi meningkat
karena garam akan menarik cairan tubuh
masuk ke pembuluh darah. Akibatnya
tekanan darah akan semakin tinggi. Bisa
menyebabkan jantung mudah lelah, bahkan
menimbulkan kematian akibat sakit jantung.</li>
          </ul>
          </div>
          <div className="text-gray-700 text-lg">
          <b>Apakah makanan yang harus dihindari atau dibatasi oleh penderita hipertensi?</b>
          <img
            src={Makanan1}
            alt="Pressure Meter"
            className="w-auto my-4 h-full object-cover"
          />
          <ul className='mx-8' style={{listStyleType: 'circle'}}>
            <li>Makanan yang mempunyai lemak jenuh
tinggi : Daging merah, otak, ginjal, paru, 
minyak kelapa, mentega, margarin, gajih, 
susu full cream, kuning telur, daging unggas
dan kulitnya, keju, dll. </li>
            <li>Makanan yang mengandung banyak garam
            natrium:<br/>
            <ul className='ms-4' style={{listStyleType: 'circle'}}>
                <li>Makanan dalam kemasan kalengan : 
sarden. Sosis, kornet, sayuran dan buah
dalam kaleng</li>
                <li>Produk mie instan
                </li>
                <li>Produk biskuit
                </li>
                <li>Makan kering yang rasanya asin</li>
                <li>Makanan yang diawetkan : dendeng, 
asinan, pindang, udang kering, ikan asin, 
abon, telur asing, selai kacang, dll.</li>
            </ul>
            </li>
            <li>Berbagai bumbu : kecap, saus tomat, terasi, 
            bumbu penyedap. </li>
            <li>Makanan dan minuman yang mengandung
            alkohol : alkohol, durian, tape, dll.</li>
          </ul>
          </div>
          <div className="text-gray-700 text-lg">
          <b>Apa yang harus dilakukan penderita hipertensi agar tekanan darah bisa terkendali dengan baik?</b>
          {/* <img
            src={Makanan1}
            alt="Pressure Meter"
            className="w-auto my-4 h-full object-cover"
          /> */}
          <ul className='mx-8' style={{listStyleType: 'circle'}}>
            <li><img
            src={Garam}
            alt="Pressure Meter"
            className="w-auto my-4 h-full object-cover"
          />
                Batasi <b>garam hanya 1 sendok teh</b> atau 6 gram digunakan dalam sehari. 
Hindari makan yang rasanya gurih karena makanan tersebut mengandung
banyak garam. Maka baca dulu komposisi makanan dalam
kemasannya/bungkusnya untuk mengetahui adanya kandungan
garam.Hindari makan makanan yang diangetkan karena akan menimbulkan
kadar garam yang lebih banyak. Penderita hipertensi yang bisa membatasi
makanan asin atau mengandung garam akan dapat menurunkan tekanan
darah hingga 6 mmHg.
</li>
            <li>Membuat makanan sendiri dari bahan yang mengandung <b>banyak sayuran
            dan buah segar.</b>
Penderita hipertensi sebaiknya makan sayuran dan buah 5-6 porsi sehari. 
Setiap kali makan, komposisi sayur dan buah sebanyak ¾ piring, sedangkan
nasi dan lauk ¼ piring. </li>
            <li>Pastikan mengkonsumsi <b>makanan berlemak kurang dari 65gram</b> setiap
hari atau porsi kecil. Batasi lauk yang banyak mengandung lemak. Hindari
makan daging merah, gorengan, kulit dan lainnya yang berminyak setiap
hari. Penderita hipertensi boleh makan daging berwarna putih seperti ikan, 
ayam. Penderita hipertensi yang bisa mengurangi makanan lemak, maka
tekanan darah akan turun hingga 11 mmHg.</li>
            <li><b>Turunkan berat badan</b> jika masuk dalam kriteria kegemukan. Ukurlah
indeks Massa Tubuh secara berkala dengan cara menimbang berat badan 
dan mengukur tinggi badan. 
Orang yang kegemukan perlu menurunkan berat badan. Setiap penurunan
1 kh berat badannya maka akan menurunkan tekanan darah 1 mmHg. </li>
            <li>
                <img
            src={Merebus}
            alt="Pressure Meter"
            className="w-auto my-4 h-full object-cover"
            />
                Memasak dengan cara merebus, mengukus dan memanggang. 
            <b>Hindari</b> memasak dengan cara <b>menggoreng</b>.</li>
            <li><img
            src={LabelFood}
            alt="Pressure Meter"
            className="w-auto my-4 h-full object-cover"
            />
                <b>Baca label makanan</b>, cek kandungan garam atau natriumnya dan lemaknya. 
Jika kadar natrium dan lemak tinggi maka sebaiknya jangan di makan. 
</li>
          </ul>
          </div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
        <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/6/2')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab6Stage3}>
            {lanjut}
          </button>
          </div>
      </div>
    </Platform>
  );
};

export default Bab6Stage3;
