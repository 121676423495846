import React from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import Happy from "../assets/heart-happy.png";
import Sad from "../assets/heart-sad.png";
import { useNavigate } from "react-router-dom";

const PopupTutorial = ({
  title,
  description,
  buttonText,
  handleClose,
  handleAction,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white text-center px-8 py-12 rounded-3xl max-w-xs w-full shadow-lg relative">
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-matahati-primary-400 font-bold text-3xl"
        >
          <IoCloseCircleSharp />
        </button>
        <h2 className="text-3xl font-black text-matahati-primary-400">{title}</h2>
        <p className="text-gray-600 mt-2 mb-8">{description}</p>
        <button
          onClick={handleAction}
          className="
            bg-matahati-secondary-200 text-xl text-white font-bold 
            py-2 px-8 rounded-full hover:bg-matahati-secondary-300 
            shadow-inner shadow-neutral-500
            cursor-pointer"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

const PopupReaction = ({
  isCorrect
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className={`${isCorrect ? 'bg-matahati-secondary-200' : 'bg-matahati-primary-400'} text-center px-8 py-12 rounded-3xl max-w-xs w-full shadow-lg relative`}>
        <img src={isCorrect ? Happy : Sad} className="w-1/2 h-auto mx-auto" alt="" />
        <h2 className="text-3xl font-black text-white">{isCorrect ? 'Hore kamu benar!' : 'Yah salah :('}</h2>
      </div>
    </div>
  );
};

const ChooseLevel = ({
  handleClose,
  gameName,
  questionsData,
  questionState
}) => {
  const gameData = JSON.parse(localStorage.getItem("user_data"))[gameName];

  // Fungsi untuk mengatur soal berdasarkan level yang dipilih
  const handleLevelSelect = (levelKey) => {
    console.log(questionsData);
    questionState(questionsData.sets[levelKey].questions);
    handleClose();
  };

  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white text-center px-8 py-12 rounded-3xl max-w-xs w-full shadow-lg relative">
        <button
          onClick={() => navigate('/island')}
          className="absolute top-3 right-3 text-matahati-primary-400 font-bold text-3xl"
        >
          <IoCloseCircleSharp />
        </button>
        <h2 className="text-3xl font-black text-matahati-primary-400">Pilih Level</h2>
        <p className="text-sm font-light text-matahati-primary-400">Kamu sudah menyelesaikan semua level</p>
        
        <div className="flex flex-wrap items-center justify-center gap-4 mt-6">
          {Object.keys(gameData).map((levelKey) => {
            const { pass, score } = gameData[levelKey];
            return (
              <button
                key={levelKey}
                onClick={() => handleLevelSelect(levelKey)} // Perbaikan: gunakan arrow function agar tidak langsung dieksekusi
                className="px-4 py-2 bg-matahati-secondary-200 shadow-inner shadow-neutral-700 hover:bg-matahati-secondary-300 text-white font-bold rounded-lg flex flex-col items-center"
              >
                <span className="text-xl">Level {levelKey}</span>
                <span className="text-xs font-light">Score: {score}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { PopupTutorial, PopupReaction, ChooseLevel};
