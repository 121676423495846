const questionData = {
  sets: [
    {
      setNumber: 1,
      data: {
        question: "Apa yang menyebabkan hipertensi?",
        answers: [
          "Keturunan",
          "Umur",
          "Kurang aktivitas",
          "Suka makan berlemak",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound1.mp3",
      },
    },
    {
      setNumber: 2,
      data: {
        question: "Apa tanda gejala hipertensi?",
        answers: ["Pusing", "Lemes", "Jantung berdebar-debar", "Mata kabur"],
        scores: [40, 30, 20, 10],
        soundFile: "sound2.mp3",
      },
    },
    {
      setNumber: 3,
      data: {
        question:
          "Apa yang dilakukan pasien hipertensi untuk menurunkan tekanan darahnya?",
        answers: [
          "Minum obat",
          "Hindari makanan asin",
          "Batasi makan berlemak",
          "Olahraga",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound3.mp3",
      },
    },
    {
      setNumber: 4,
      data: {
        question:
          "Apa komplikasi yang dapat timbul jika hipertensi tidak diatasi?",
        answers: ["Stroke", "Sakit ginjal", "Sakit jantung", "Kebutaan"],
        scores: [40, 30, 20, 10],
        soundFile: "sound4.mp3",
      },
    },
    {
      setNumber: 5,
      data: {
        question:
          "Bagaimana cara masak yang dianjurkan untuk pasien hipertensi?",
        answers: ["Direbus", "Dikukus", "Dipanggang", "Diasap"],
        scores: [40, 30, 20, 10],
        soundFile: "sound5.mp3",
      },
    },
    {
      setNumber: 6,
      data: {
        question:
          "Apa yang harus dilakukan penderita hipertensi supaya tekanan darah terkendali?",
        answers: [
          "Batasi garam 1 sendok teh",
          "Makan banyak sayuran",
          "Hindari makanan berlemak",
          "Olahraga",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound6.mp3",
      },
    },
    {
      setNumber: 7,
      data: {
        question: "Jenis olahraga apa yang dianjurkan untuk pasien hipertensi?",
        answers: ["Jalan cepat", "Bersepeda", "Yoga", "Senam ringan"],
        scores: [40, 30, 20, 10],
        soundFile: "sound7.mp3",
      },
    },
    {
      setNumber: 8,
      data: {
        question: "Makanan apa yang baik untuk pasien hipertensi?",
        answers: ["Buah", "Sayur", "Ikan", "Kacang-kacangan"],
        scores: [40, 30, 20, 10],
        soundFile: "sound8.mp3",
      },
    },
    {
      setNumber: 9,
      data: {
        question: "Gaya hidup apa yang mempengaruhi hipertensi?",
        answers: ["Kurang aktivitas", "Minum alkohol", "Merokok", "Obesitas"],
        scores: [40, 30, 20, 10],
        soundFile: "sound9.mp3",
      },
    },
    {
      setNumber: 10,
      data: {
        question: "Apa kesalahan umum dalam menjaga tekanan darah?",
        answers: [
          "Tidak olahraga",
          "Pola makan tidak teratur",
          "Konsumsi garam berlebihan",
          "Merokok",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound10.mp3",
      },
    },
    {
      setNumber: 11,
      data: {
        question: "Hal apa yang bisa meningkatkan tekanan darah?",
        answers: ["Makan tinggi garam", "Stress", "Kurang olahraga", "Merokok"],
        scores: [40, 30, 20, 10],
        soundFile: "sound11.mp3",
      },
    },
    {
      setNumber: 12,
      data: {
        question: "Apa yang dirasakan pasien hipertensi?",
        answers: ["Sakit kepala", "Pusing", "Penglihatan kabur", "Sesak nafas"],
        scores: [40, 30, 20, 10],
        soundFile: "sound12.mp3",
      },
    },
    {
      setNumber: 13,
      data: {
        question: "Makanan apa yang sebaiknya dihindari penderita hipertensi?",
        answers: [
          "Makanan yang mengandung garam",
          "Makanan olahan",
          "Daging merah",
          "Gorengan",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound13.mp3",
      },
    },
    {
      setNumber: 14,
      data: {
        question: "Bagaimana upaya mencegah hipertensi?",
        answers: [
          "Mengurangi asupan garam",
          "Rutin olahraga",
          "Tidak merokok",
          "Makan buah dan sayur",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound14.mp3",
      },
    },
    {
      setNumber: 15,
      data: {
        question: "Apa komplikasi hipertensi?",
        answers: ["Stroke", "Serangan jantung", "Gagal ginjal", "Kebutaan"],
        scores: [40, 30, 20, 10],
        soundFile: "sound15.mp3",
      },
    },
    {
      setNumber: 16,
      data: {
        question: "Siapa saja yang memiliki risiko hipertensi?",
        answers: ["Lansia", "Riwayat keluarga", "Obesitas", "Perokok"],
        scores: [40, 30, 20, 10],
        soundFile: "sound16.mp3",
      },
    },
    {
      setNumber: 17,
      data: {
        question: "Apa dampak hipertensi?",
        answers: [
          "Penyakit jantung",
          "Gangguan penglihatan",
          "Gangguan syaraf",
          "Kematian dini",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound17.mp3",
      },
    },
    {
      setNumber: 18,
      data: {
        question: "Kebiasaan sehat apa yang dapat mengendalikan tekanan darah?",
        answers: ["Rutin kontrol", "Minum obat", "Diet", "Kelola stress"],
        scores: [40, 30, 20, 10],
        soundFile: "sound18.mp3",
      },
    },
    {
      setNumber: 19,
      data: {
        question: "Apa tanda tekanan darah tinggi yang berbahaya?",
        answers: ["Pusing hebat", "Sakit dada", "Mata kabur", "Lelah"],
        scores: [40, 30, 20, 10],
        soundFile: "sound19.mp3",
      },
    },
    {
      setNumber: 20,
      data: {
        question: "Jenis faktor risiko hipertensi yang dapat diubah adalah?",
        answers: [
          "Konsumsi garam berlebih",
          "Kurang aktivitas fisik",
          "Stres",
          "Merokok",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound20.mp3",
      },
    },
    {
      setNumber: 21,
      data: {
        question: "Apa tanda gejala yang dirasakan pasien hipertensi?",
        answers: ["Sakit kepala", "Mata kabur", "Gelisah", "Jantung berdebar"],
        scores: [40, 30, 20, 10],
        soundFile: "sound21.mp3",
      },
    },
    {
      setNumber: 22,
      data: {
        question: "Bagaimana cara mengendalikan hipertensi?",
        answers: [
          "Kontrol teratur",
          "Rutin minum obat",
          "Batasi makan asin",
          "Hindari asap rokok",
        ],
        scores: [40, 30, 20, 10],
        soundFile: "sound22.mp3",
      },
    },
  ],
};
export default questionData;
