import React, { useState, useEffect } from "react";
import Platform from "./platform";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import {PopupTutorial, PopupReaction, ChooseLevel} from "../components/Popup";
import ResultComponent from "../components/Result";
import { api, getAuthHeader } from "../controllers/getUserInfo";
import pictGuessQuestions from "../data/pictGuessQuestions";
import fetchPackage from "../controllers/getLevelToPlay";
import image_1 from "../assets/tebakGambar/Tebak_Gambar_1.png";
import image_2 from "../assets/tebakGambar/Tebak_Gambar_2.png";
import image_3 from "../assets/tebakGambar/Tebak_Gambar_3.png";
import image_4 from "../assets/tebakGambar/Tebak_Gambar_4.png";
import image_5 from "../assets/tebakGambar/Tebak_Gambar_5.png";
import image_6 from "../assets/tebakGambar/Tebak_Gambar_6.png";
import image_7 from "../assets/tebakGambar/Tebak_Gambar_7.png";
import image_8 from "../assets/tebakGambar/Tebak_Gambar_8.png";
import image_9 from "../assets/tebakGambar/Tebak_Gambar_9.png";
import image_10 from "../assets/tebakGambar/Tebak_Gambar_10.png";
import image_11 from "../assets/tebakGambar/Tebak_Gambar_11.png";
import image_12 from "../assets/tebakGambar/Tebak_Gambar_12.png";
import image_13 from "../assets/tebakGambar/Tebak_Gambar_13.png";
import image_14 from "../assets/tebakGambar/Tebak_Gambar_14.png";
import image_15 from "../assets/tebakGambar/Tebak_Gambar_15.png";
import image_16 from "../assets/tebakGambar/Tebak_Gambar_16.png";
import image_17 from "../assets/tebakGambar/Tebak_Gambar_17.png";
import image_18 from "../assets/tebakGambar/Tebak_Gambar_18.png";
import image_19 from "../assets/tebakGambar/Tebak_Gambar_19.png";
import image_20 from "../assets/tebakGambar/Tebak_Gambar_20.png";
import image_21 from "../assets/tebakGambar/Tebak_Gambar_21.png";
import image_22 from "../assets/tebakGambar/Tebak_Gambar_22.png";
import image_23 from "../assets/tebakGambar/Tebak_Gambar_23.png";
import image_24 from "../assets/tebakGambar/Tebak_Gambar_24.png";
import image_25 from "../assets/tebakGambar/Tebak_Gambar_25.png";

const PictGuess = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [score, setScore] = useState(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [showPopup, setShowPopup] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [questionPackage, setQuestionPackage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [popupLevel, setPopupLevel] = useState(false);
  const [showPopupReaction, setShowPopupReaction] = useState(false);
  const images = {
    "Tebak_Gambar_1.png": image_1,
    "Tebak_Gambar_2.png": image_2,
    "Tebak_Gambar_3.png": image_3,
    "Tebak_Gambar_4.png": image_4,
    "Tebak_Gambar_5.png": image_5,
    "Tebak_Gambar_6.png": image_6,
    "Tebak_Gambar_7.png": image_7,
    "Tebak_Gambar_8.png": image_8,
    "Tebak_Gambar_9.png": image_9,
    "Tebak_Gambar_10.png": image_10,
    "Tebak_Gambar_11.png": image_11,
    "Tebak_Gambar_12.png": image_12,
    "Tebak_Gambar_13.png": image_13,
    "Tebak_Gambar_14.png": image_14,
    "Tebak_Gambar_15.png": image_15,
    "Tebak_Gambar_16.png": image_16,
    "Tebak_Gambar_17.png": image_17,
    "Tebak_Gambar_18.png": image_18,
    "Tebak_Gambar_19.png": image_19,
    "Tebak_Gambar_20.png": image_20,
    "Tebak_Gambar_21.png": image_21,
    "Tebak_Gambar_22.png": image_22,
    "Tebak_Gambar_23.png": image_23,
    "Tebak_Gambar_24.png": image_24,
    "Tebak_Gambar_25.png": image_25,
  };

  const getUserInfo = async () => {
    try {
      const response = await api.get("/api/user", {
        headers: getAuthHeader(),
      });
      console.log("User Info:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Failed to fetch user info:",
        error.response ? error.response.status : error.message
      );
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const storeNewScore = async () => {
    try {
      const userId = userInfo.id;
      const score = correctAnswers * 20; // Hitung skor berdasarkan jawaban benar

      // Kirim data skor ke server dengan endpoint khusus untuk guessPict
      const response = await api.post(
        "/api/post-tebak-gambar",
        {
          user_id: userId,
          set: questionPackage,
          score: score,
          completed: score >= 80,
        },
        {
          headers: getAuthHeader(),
        }
      );

      // Ambil data pengguna yang tersimpan di localStorage
      const previousData = JSON.parse(localStorage.getItem("user_data"));
      if (!previousData) {
        navigate("/login"); // Jika data tidak ditemukan, arahkan ke halaman login
        return;
      }

      // Update skor dan status kelulusan untuk game `guessPict`
      previousData.tebak_gambar[questionPackage].pass = score >= 80; // Kelulusan berdasarkan threshold 80
      previousData.tebak_gambar[questionPackage].score = score; // Simpan skor terbaru

      // Simpan perubahan ke localStorage
      localStorage.setItem("user_data", JSON.stringify(previousData));

      console.log("User Info:", response.data);
    } catch (error) {
      console.error(
        "Failed to store score:",
        error.response ? error.response.status : error.message
      );
      if (error.response && error.response.status === 401) {
        navigate("/login"); // Jika ada kesalahan autentikasi, arahkan ke login
      }
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userData = await getUserInfo();
      setUserInfo(userData);
      const set = await fetchPackage("tebak_gambar", "/game/guess-the-picture");
      if (set) {
        setQuestionPackage(set);
        setIsLoading(false);
        setQuestions(dataQuestion.sets[set - 1].questions);
      } else {
        setPopupLevel(true);
        setShowPopup(false);
      }
    };
    fetchUserInfo();
    setScore(0);
    const dataQuestion = pictGuessQuestions;
    setCurrentQuestionIndex(0);
  }, []);

  useEffect(() => {
    if (pictGuessQuestions.sets[questionPackage]) {
      setQuestions(pictGuessQuestions.sets[questionPackage].questions);
    } else {
      console.error(
        "Package not found. Please check packageNum or questions data."
      );
    }
  }, [questionPackage]);

  useEffect(() => {
    if (isGameOver && userInfo) {
      storeNewScore();
    }
  }, [isGameOver, userInfo]);

  useEffect(() => {
    if (questions.length > 0) {
      const wasAnsweredCorrectly =
        answeredQuestions[currentQuestionIndex] === "correct";
      setAnswer(
        wasAnsweredCorrectly ? questions[currentQuestionIndex].answer : ""
      );
      setDisabled(wasAnsweredCorrectly);
      setIsCorrect(null);
    }
  }, [currentQuestionIndex, questions]);

  const handleAnswerChange = (e) => {
    if (!disabled) setAnswer(e.target.value);
  };

  const handleSubmit = () => {
    if (answeredQuestions[currentQuestionIndex] === "correct") {
      handleNextQuestion();
      return;
    }
  
    const userAnswer = answer.trim().toLowerCase();
    const correctAnswer = questions[currentQuestionIndex].answer.toLowerCase();
  
    setIsLoadingNext(true);
  
    setTimeout(() => {
      setIsLoadingNext(false);
      if (userAnswer === correctAnswer) {
        setIsCorrect(true);
        setScore((prevScore) => prevScore + 20);
        setAnsweredQuestions((prev) => ({
          ...prev,
          [currentQuestionIndex]: "correct",
        }));
        setDisabled(true);
        setShowPopupReaction(true);
        setTimeout(() => setShowPopupReaction(false), 2000);
      } else {
        setIsCorrect(false);
        setAnsweredQuestions((prev) => ({
          ...prev,
          [currentQuestionIndex]: "incorrect",
        }));
        setAnswer(""); // Clear the answer field when the answer is incorrect
        setShowPopupReaction(true);
        setTimeout(() => setShowPopupReaction(false), 2000);
      }
    }, 1000);
  };
  

  const handleClosePopupReaction = () => {
    setShowPopupReaction(false); // Menutup PopupReaction setelah beberapa saat atau aksi
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsCorrect(null);
      setDisabled(false);
    } else {
      setIsGameOver(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setIsCorrect(null);
      setDisabled(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handlePlay = () => {
    setShowPopup(false);
    handlePlayGame();
  };

  const handlePlayGame = () => {
    setScore(0);
    setCurrentQuestionIndex(0);
    setAnsweredQuestions({});
    setIsGameOver(false);
  };

  const handleCloseLevel = () => {
    setPopupLevel(false);
  }

  if (isLoading) {
    return (
      <Platform>
        <div className="w-full h-full flex flex-col items-center">
          <div className="bg-matahati-primary-300 min-h-screen w-full py-6 flex justify-center items-center text-white font-semibold">
            Loading Game....
          </div>
        </div>
      </Platform>
    );
  }

  if (questions.length === 0) return;

  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;
  const correctAnswers = score / 20;
  const incorrectAnswers = totalQuestions - correctAnswers;
  const accuracy = ((correctAnswers / totalQuestions) * 100).toFixed(0);

  return (
    <Platform>
      <div className="bg-matahati-primary-300 h-full text-white">
        {showPopup && (
          <PopupTutorial
            title="Tebak Gambar"
            description="Pemain harus menjawab pertanyaan dengan bantuan gambar yang telah disediakan."
            buttonText="Mainkan"
            handleClose={handleClosePopup}
            handleAction={handlePlay}
          />
        )}
        {popupLevel && (
          <ChooseLevel
            handleClose={handleCloseLevel}
            gameName={"tebak_gambar"}
            questionsData={pictGuessQuestions}
            questionState={setQuestions}
          />
        )}
        {!showPopup && !isGameOver && (
          <>
            {showPopupReaction && <PopupReaction isCorrect={isCorrect} />}
            <div className="w-full h-full flex flex-col py-6 px-8 items-center">
                <BackButton title={"Tebak Gambar"} />
                <div className="flex gap-2 mb-6">
                  {questions.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentQuestionIndex(index)}
                      className={`w-8 h-8 rounded-lg border-white text-lg ${
                        answeredQuestions[index] === "correct"
                          ? "bg-matahati-secondary-200"
                          : answeredQuestions[index] === "incorrect"
                          ? "bg-matahati-primary-400"
                          : "bg-matahati-primary-300 border border-1 border-white"
                      } ${
                        index === currentQuestionIndex
                          ? "border-2 border-matahati-primary-500"
                          : ""
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <div className="bg-white text-matahati-primary-500 font-bold rounded-3xl py-5 px-4 text-center text-lg mb-8 w-full shadow-md min-h-[8rem] flex items-center justify-center">
                  {currentQuestion.question}
                </div>

                <div className="w-72 h-44 bg-white rounded-lg mb-6 shadow-md flex justify-center items-center">
                  <img
                    src={images[currentQuestion.imageKey]}
                    alt="Question"
                    className="w-full h-full object-contain"
                  />
                </div>
                <input
                  type="text"
                  value={answer}
                  onChange={handleAnswerChange}
                  className="w-60 max-w-xs outline-none rounded-lg py-2 px-4 mt-8 text-center text-white border-b-2 border-white bg-matahati-primary-300 placeholder-slate-200 mb-8"
                  placeholder="Tulis jawaban di sini"
                  disabled={disabled}
                />

                <button
                  onClick={
                    answeredQuestions[currentQuestionIndex] === "correct" ||
                    isCorrect
                      ? handleNextQuestion
                      : handleSubmit
                  }
                  className={`w-32 py-2 rounded-full ${
                    isLoadingNext ? "bg-gray-400" : "bg-matahati-secondary-200"
                  } text-white`}
                  disabled={isLoadingNext}
                >
                  {isLoadingNext
                    ? "Loading..."
                    : currentQuestionIndex === questions.length - 1 &&
                      (isCorrect ||
                        answeredQuestions[currentQuestionIndex] === "correct")
                    ? "Selesai"
                    : isCorrect ||
                      answeredQuestions[currentQuestionIndex] === "correct"
                    ? "Selanjutnya"
                    : "Jawab"}
                </button>

                <div className="flex w-full mt-6 justify-between gap-4">
                  <button
                    onClick={handlePreviousQuestion}
                    className="bg-matahati-primary-300 w-32 border border-white text-white font-semibold text-xs py-2 px-6 rounded-3xl"
                    disabled={currentQuestionIndex === 0}
                  >
                    Sebelumnya
                  </button>
                  <button
                    onClick={
                      currentQuestionIndex === questions.length - 1
                        ? () => setIsGameOver(true) // Finish the game if on the last question
                        : handleNextQuestion
                    }
                    className="bg-matahati-primary-400 w-32 text-white font-semibold text-xs px-6 rounded-3xl"
                  >
                    {currentQuestionIndex === questions.length - 1
                      ? "Selesai"
                      : "Lewati"}
                  </button>
                </div>
              </div>
          </>
        )}
        {isGameOver && (
          <ResultComponent
            origin={"/game/guess-the-picture"}
            score={accuracy}
            correct={correctAnswers}
            incorrect={incorrectAnswers}
          />
        )}
      </div>
    </Platform>
  );
};

export default PictGuess;
