import React, { useEffect, useState } from "react";
import axios from "axios";
import Platform from "./platform";
import handleTextInput from "../controllers/handleInputs";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [registerState, setRegisterState] = useState('Buat Akun');
  
  const navigate = useNavigate();

  const getCsrfToken = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`, {
      withCredentials: true
    });
  };

  const handleSubmitRegister = async (event) => {
    event.preventDefault();

    if (!username || !name) {
      setRegisterError('Semua kolom harus diisi.');
      return;
    }

    try {
      setRegisterState('Tunggu sebentar...')
      await getCsrfToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/register`,
        { name, username },
        {
          withCredentials: true
        }
      )
      console.log(response)
      setRegisterState('Selesai Buat Akun')
      setRegisterError('');
      navigate('/login');
    } catch (error) {
      console.error('Register failed:', error);
      setRegisterState('Buat Akun')
      if (error.response.status === 422) {
        setRegisterError(error.response.data.message);
      } else {
        setRegisterError('Registrasi gagal. Silakan coba lagi.');
      }
    }
  };

  useEffect(() => {
    setRegisterError('');
  }, [username, email, password, confirmPassword]);

  return (
    <Platform>
      <div className="h-full bg-white flex flex-col items-center justify-center px-8">
        <div className="flex flex-col w-full h-full items-center justify-center gap-8">
          <h1 className="text-matahati-primary-400 font-bold  text-5xl">
            Buat Akun
          </h1>
          <form
            className="w-full flex flex-col gap-4 items-center"
            onSubmit={handleSubmitRegister}
          >
            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="username">Nama</label>
              <input
                type="text"
                id="name"
                placeholder="nama"
                className="border border-neutral-500 rounded-lg ps-2 py-2 font-light"
                value={name}
                onChange={handleTextInput(setName)}
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                placeholder="username"
                className="border border-neutral-500 rounded-lg ps-2 py-2 font-light"
                value={username}
                onChange={handleTextInput(setUsername)}
              />
              <p className="text-matahati-primary-400 text-sm">
                {registerError}
              </p>
            </div>
            <button
              type="submit"
              className="bg-matahati-primary-300 font-bold text-white text-lg items-center rounded-full px-16 py-2 shadow-inner shadow-gray-800"
            >
              {registerState}
            </button>
            <p className="text-center font-thin">
              Sudah punya akun?{" "}
              <a
                href="/login"
                className="font-bold text-matahati-primary-400 underline"
              >
                Login Lewat Sini!
              </a>
            </p>
          </form>
        </div>
      </div>
    </Platform>
  );
}

export default Register;