import axios from "axios";
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
      'Content-Type': 'application/json',
  },
  withCredentials: true
});

// Function to attach the token to the header
const getAuthHeader = () => {
  const token = localStorage.getItem('access_token');
  const tokenType = localStorage.getItem('token_type') || 'Bearer';
  return { Authorization: `${tokenType} ${token}` };
};

export {api, getAuthHeader};