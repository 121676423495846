import React, { useEffect, useState } from 'react';
import Platform from '../../platform';
import Pusing from '../assets/pusing.png';
import Gelisah from '../assets/gelisah.png';
import Berdebar from '../assets/berdebar.png';
import Rabun from '../assets/rabun.png';
import Dada from '../assets/dada.png';
import Lelah from '../assets/lelah.png';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api, getAuthHeader } from '../../../controllers/getUserInfo';

const Bab3Stage1 = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [lanjut, setLanjut] = useState("Selanjutnya");
  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
          headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        setUserId(response.data.id);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const completeBab3Stage1 = async () => {
    try {
      setLanjut("Tunggu...");
      const response = await api.post('/api/complete-edukasi', 
        { user_id:userId, bab:3, stage:1},
        {
          headers: getAuthHeader()
      });

      const previousData = JSON.parse(localStorage.getItem('user_data'));
      if (!previousData) {
        navigate('/login');
      }
                  //atur nama gamenya di bawah ini
      previousData.progres_edukasi[3][1] = true;
      localStorage.setItem('user_data', JSON.stringify(previousData)); //simpan perubahan ke localstorage

      console.log('User Info:', response.data);

      setLanjut("Selanjutnya");

      navigate('/edukasi/4/1');
        
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
    }
  }
  return (
    <Platform>
      <div className="max-w-md mx-auto h-screen overflow-y-auto hide-scrollbar">
        <div className="sticky top-0 flex bg-white px-8 py-4 shadow-lg items-center justify-between">
          <button className="text-xl">
            <BsArrowLeftCircleFill className="text-matahati-primary-400" onClick={() => navigate('/island')}/>
          </button>
          <h2 className="text-2xl mx-4 font-bold text-matahati-primary-400">Kenali Tanda Gejala Hipertensi</h2>
          <button onClick={() => navigate('/edukasi/4/1')} className="bg-white shadow-inner shadow-neutral-700 text-matahati-primary-400 px-4 py-2 rounded-lg font-semibold">
            Lewati
          </button>
        </div>

        <div className="bg-gray-100 pt-4 px-8 rounded-xl mt-4">
          <h3 className="text-matahati-primary-400 font-bold text-xl mb-2">Kenali Gejala Hipertensi yang Sering Muncul!</h3>
          
          <div className="text-gray-700 text-lg grid grid-cols-2 gap-4">
  <div className='border rounded-lg p-4 shadow-lg'>
    <b>Sakit Kepala</b>
    <br/>
    (Paling Sering)
    <img
      src={Pusing}
      alt="Pressure Meter"
      className="w-auto my-2 h-32 object-cover"
    />
  </div>
  <div className='border rounded-lg p-4 shadow-lg'>
    <b>Gelisah</b>
    <br/>
    <br/>
    <img
      src={Gelisah}
      alt="Pressure Meter"
      className="w-auto my-2 h-32 object-cover"
    />
  </div>
  <div className='border rounded-lg p-4 shadow-lg'>
    <b>Jantung Berdebar</b>
    <br/>
    <img
      src={Berdebar}
      alt="Pressure Meter"
      className="w-auto my-2 h-32 object-cover"
    />
  </div>
  <div className='border rounded-lg p-4 shadow-lg'>
    <b>Penglihatan Kabur</b>
    <br/>
    <img
      src={Rabun}
      alt="Pressure Meter"
      className="w-auto my-2 h-32 object-cover"
    />
  </div>
  <div className='border rounded-lg p-4 shadow-lg'>
    <b>Rasa Sakit di Dada</b>
    <br/>
    <img
      src={Dada}
      alt="Pressure Meter"
      className="w-auto my-2 h-32 object-cover"
    />
  </div>
  <div className='border rounded-lg p-4 shadow-lg'>
    <b>Mudah Lelah</b>
    <br/>
    <br/>
    <img
      src={Lelah}
      alt="Pressure Meter"
      className="w-auto my-2 h-32 object-cover"
    />
  </div>
</div>
        </div>

        {/* buat 2 button di bawah, yaitu next dan skip */}
        <div className="flex justify-between p-8">
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={() => navigate('/edukasi/2/2')}>
            Sebelumnya
          </button>
          <button className="bg-white text-matahati-primary-400 shadow-inner shadow-neutral-500 px-4 py-2 rounded-lg font-bold" onClick={completeBab3Stage1}>
            {lanjut}
          </button>
        </div>
      </div>
    </Platform>
  );
};

export default Bab3Stage1;
