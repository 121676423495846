import React, { useEffect } from "react";
import Little from "./assets/PULAU/LittleIsland.png";
import { useNavigate } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";

function Island({ id, name, imgSrc, unlocked, onPlay, percentage }) {
  const navigate = useNavigate();
  const smallIslands = [
    { imgSrc: Little, position: { top: 20, left: 16 }, number: 1 },
    { imgSrc: Little, position: { top: 10, left: 50 }, number: 2 },
    { imgSrc: Little, position: { top: 20, left: 84 }, number: 3 },
    { imgSrc: Little, position: { top: 80, left: 16 }, number: 4 },
    { imgSrc: Little, position: { top: 90, left: 50 }, number: 5 },
    { imgSrc: Little, position: { top: 80, left: 84 }, number: 6 },
  ];
  const percent = parseInt(percentage).toFixed(0);
  const progress = JSON.parse(localStorage.getItem('user_data')).progres_edukasi;
  const completed = Object.values(progress[id]);

  return (
    <div className="relative text-center w-60 h-64 overflow-hidden mx-auto">
      <div>
        {/* Main Island Image */}
        <div className="absolute inset-0 flex items-center justify-center p-6">
          <img
            src={imgSrc}
            alt={name}
            className={`h-36 w-auto rounded-lg ${unlocked ? "grayscale-0" : "grayscale"}`}
          />
        </div>
        {/* Island Name Overlay */}
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="font-bold text-xl w-36 text-white p-2 backdrop-blur backdrop-opacity-50 rounded-xl" onClick={onPlay} role="button">
            {name}
            <br/>
            <div className="text-white">{percent}%</div>
            <div className="w-full h-1 bg-gray-300 rounded-full mt-1">
              <div className={`h-full ${percent < 33 ? 'bg-red-500' : (percent < 66 ? 'bg-yellow-500' : 'bg-green-500')} rounded-full`} style={{width:`${percent}%`}}></div>
            </div>
            </div>
        </div>
      </div>

      {/* Small Islands with Numbers */}
      {id === 1 && smallIslands.slice(0,3).map((smallIsland, index) => (
        <React.Fragment key={index}>
          {/* Number above each small island */}
          <div onClick={() => navigate(`/edukasi/${id}/${smallIsland.number}`)} role="button">
            <div
              className={`absolute text-white text-xl font-bold z-10`}
              style={{
                top: `${smallIsland.position.top}%`, // Position number slightly above the island
                left: `${smallIsland.position.left}%`,
                transform: "translate(-50%, -50%)",
              }}
            >
              {completed[index] && (
                <BsCheckCircleFill className="absolute -top-2 left-5 transform -translate-x-1/2 text-green-500" />
              )}
              {smallIsland.number}
            </div>
            {/* Small Island Image */}
            <img
              src={smallIsland.imgSrc}
              alt={`Small Island ${index + 1}`}
              className="absolute w-16 h-16 rounded-full"
              style={{
                top: `${smallIsland.position.top}%`,
                left: `${smallIsland.position.left}%`,
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        </React.Fragment>
      ))}
      {id === 2 && smallIslands.slice(0,2).map((smallIsland, index) => (
        <React.Fragment key={index}>
          {/* Number above each small island */}
          <div onClick={() => navigate(`/edukasi/${id}/${smallIsland.number}`)} role="button">
          <div
            className="absolute text-white font-bold text-xl z-10"
            style={{
              top: `${smallIsland.position.top}%`, // Position number slightly above the island
              left: `${smallIsland.position.left}%`,
              transform: "translate(-50%, -50%)",
            }}
          >
              {completed[index] && (
                <BsCheckCircleFill className="absolute -top-2 left-5 transform -translate-x-1/2 text-green-500" />
              )}
              {smallIsland.number}
            </div>
          {/* Small Island Image */}
          <img
            src={smallIsland.imgSrc}
            alt={`Small Island ${index + 1}`}
            className="absolute w-16 h-16 rounded-full"
            style={{
              top: `${smallIsland.position.top}%`,
              left: `${smallIsland.position.left}%`,
              transform: "translate(-50%, -50%)",
            }}
          />
          </div>
        </React.Fragment>
      ))}
      {id > 2 && id < 6 && smallIslands.slice(0,1).map((smallIsland, index) => (
        <React.Fragment key={index}>
          {/* Number above each small island */}
          <div onClick={() => navigate(`/edukasi/${id}/${smallIsland.number}`)} role="button">
          <div
            className="absolute text-white font-bold text-xl z-10"
            style={{
              top: `${smallIsland.position.top}%`, // Position number slightly above the island
              left: `${smallIsland.position.left}%`,
              transform: "translate(-50%, -50%)",
            }}
          >
              {completed[index] && (
                <BsCheckCircleFill className="absolute -top-2 left-5 transform -translate-x-1/2 text-green-500" />
              )}
              {smallIsland.number}
            </div>
          {/* Small Island Image */}
          <img
            src={smallIsland.imgSrc}
            alt={`Small Island ${index + 1}`}
            className="absolute w-16 h-16 rounded-full"
            style={{
              top: `${smallIsland.position.top}%`,
              left: `${smallIsland.position.left}%`,
              transform: "translate(-50%, -50%)",
            }}
          />
          </div>
        </React.Fragment>
      ))}
      {id === 6 && smallIslands.map((smallIsland, index) => (
        <React.Fragment key={index}>
          {/* Number above each small island */}
          <div onClick={() => navigate(`/edukasi/${id}/${smallIsland.number}`)} role="button">
          <div
            className="absolute text-white font-bold text-xl z-10"
            style={{
              top: `${smallIsland.position.top}%`, // Position number slightly above the island
              left: `${smallIsland.position.left}%`,
              transform: "translate(-50%, -50%)",
            }}
          >
              {completed[index] && (
                <BsCheckCircleFill className="absolute -top-2 left-5 transform -translate-x-1/2 text-green-500" />
              )}
              {smallIsland.number}
            </div>
          {/* Small Island Image */}
          <img
            src={smallIsland.imgSrc}
            alt={`Small Island ${index + 1}`}
            className="absolute w-16 h-16 rounded-full"
            style={{
              top: `${smallIsland.position.top}%`,
              left: `${smallIsland.position.left}%`,
              transform: "translate(-50%, -50%)",
            }}
          />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Island;
