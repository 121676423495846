import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Platform from "./platform";
import Logo from "../assets/logo_transparent.png";
import Background from "../assets/background_with_object.png";
import { api, getAuthHeader } from "../controllers/getUserInfo";

// URL background PNG yang Anda upload
const backgroundUrl = Background;

const Dashboard = () => {
  const [greet, setGreet] = useState("");
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const getUserInfo = async () => {
    try {
        const response = await api.get('/api/user', {
            headers: getAuthHeader()
        });
        console.log('User Info:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user info:', error.response ? error.response.status : error.message);
        if (error.response && error.response.status === 401) {
            navigate('/login');
        }
    }
  };

  const greetings = [
    "Mari Hidup Sehat Tanpa Hipertensi!",
    "Selamat Datang di Zona Sehat!",
    "Ayo Belajar Tentang Hipertensi!",
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * greetings.length);
    setGreet(greetings[randomIndex]);
    const fetchUserData = async () => {
      const userData = await getUserInfo();
      setUserData(userData);
    };

    fetchUserData();
  }, []);

  const handlePlayClick = () => {
    navigate("/island");
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/splash', {replace: true});
  }

  return (
    <Platform>
      <div
        className="relative flex flex-col items-center justify-center py-10 h-full text-black px-8 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundUrl})`
        }}
      >
        <div className="self-start mb-4 absolute top-8 left-8">
          <p className="text-white font-semibold">Hai,</p>
          {userData && (
            <h1 className="text-white font-bold text-xl">{userData.name}</h1>
          )}
        </div>

        <div className="flex flex-col items-center mb-8">
          <img src={Logo} alt="Character Logo" className="w-32 h-auto mb-4" />
          <h2 className="text-2xl font-bold text-white text-center">{greet}</h2>
        </div>

        <button
          onClick={handlePlayClick}
          className="w-48 h-16 mb-4 rounded-full bg-matahati-secondary-200 text-white text-lg font-bold  shadow-inner shadow-neutral-700 flex items-center justify-center hover:bg-matahati-secondary-300"
        >
          Mulai
        </button>
        <button
          onClick={handleLogout}
          className="w-48 h-16 mb-4 rounded-full bg-matahati-primary-400 text-white text-lg font-bold  shadow-inner shadow-neutral-700 flex items-center justify-center hover:bg-matahati-primary-500"
        >
          Logout
        </button>
      </div>
    </Platform>
  );
};

export default Dashboard;
